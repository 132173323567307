.email-form {
  text-align: left;
  font-size: var(--font-3);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  /* flex-wrap: wrap; */
  gap: 0.8rem;
  position: relative;
  align-items: stretch;
  height: fit-content;
}

.email-form__inputs {
  width: 100%;
  border: none;
  display: flex;
  flex-flow: column;
  gap: 0.8rem;
}

.email-form .email-form__section:not(:last-of-type) {
  height: 5.2rem;
  display: flex;
  flex-flow: row;
}

.email-form .email-form__label {
  display: block;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.email-form .email-form__section {
  width: 100%;
  display: flex;
  flex-flow: row;
  gap: 0.8rem;
  grid-column-start: 1;
  grid-column-end: 3;
}

.email-form--email {
  min-width: calc(50% - 0.45rem);
}

.email-form input {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 2px;
  height: 5.2rem;
  min-width: calc(50% - 0.4rem);
  flex: 1 1;
}

.email-form input[type=submit] {
  width: fit-content;
  background-color: var(--primary-9);
  color: white;
  flex-shrink: 1;
  min-width: unset;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  height: unset;
  border-radius: 0.4rem;
}

.email-form__feedback {
  --color: var(--red);
  grid-column-start: 1;
  grid-column-end: 3;
  border: 2px solid currentColor;
  color: var(--color);
  font-weight: bold;
  padding: 0.8rem 1.8rem;
  min-height: 5.8rem;
  border-radius: 0.4rem;
  padding-left: 7.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: white;
}

.email-form__feedback::before {
  content: "\f00d";
  font-family: 'fontawesome';
  width: 5.8rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--color);
}

.email-form__feedback--success {
  --color: var(--green);
}

.email-form__feedback--success::before {
  content: "\f00c";
}

.email-form__feedback:empty {
  display: none;
}

.email-form__sms-disclosure {
  text-align: center;
  width: 100%;
  font-size: var(--font-2);
  color: var(--gray-5);
  grid-column-start: 1;
  grid-column-end: 3;
}

@media (max-width: 850px) {
  .email-form {
    width: 100%;
    flex-flow: column;
    display: flex;
 }
 .email-form__sms-disclosure {
  color: var(--gray-0);
 }
  .email-form .email-form__section {
    flex-flow: column;
    height: auto !important;
    margin: 0 !important;
 }
  .email-form input[type=submit] {
    margin: 0;
 }
 .email-form input[type=email] {
  margin: 0 !important;
  height: 5.2rem;
 }
}
