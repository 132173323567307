.nav {
  --nav-color: var(--primary-9);
  --nav-border: 0px solid transparent;
  max-width: var(--site-width);
  height: 10.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  margin: 0 auto;
  border-bottom: var(--nav-border);
  background-color: var(--nav-color);
  position: relative;
  padding: 0 1.8rem 0 0;
  gap: 1.8rem;
  box-sizing: content-box;
}

.nav--light {
  --nav-color: var(--primary-1);
}

.nav--white {
  --nav-color: var(--primary-0);
  --nav-border: 1px solid var(--primary-10);
}

.nav--black {
  --nav-color: var(--primary-10);
}



.nav::before {
  content: "";
  width: 50vw;
  right: 50%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
  background: var(--nav-color);
  border-bottom: var(--nav-border);
  box-sizing: content-box;
}

.nav::after {
  content: "";
  width: 50vw;
  left: 50%;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
  background: var(--primary-5);
  border-bottom: var(--nav-border);
  box-sizing: content-box;
}

.nav__menu-button {
  display: none;
  position: absolute;
  box-sizing: border-box;
  right: -5.8rem;
  width: 48px;
  height: 48px;
  background: transparent;
  top: 0;
  color: transparent;
  border-radius: 0;
  padding: 13px 0;
  border: 8px solid transparent;
  border-width: 8px 6px;
  color: var(--primary);
  line-height: 0;
  font-weight: 900;
  text-shadow: 1px 0 0;
  font-size: 1.38rem;
  text-indent: -0.04rem;
  font-family: Arial;
  background-clip: padding-box;
}

.nav__menu-button::before, .nav__menu-button::after {
  content: "";
  width: 100%;
  height: 6px;
  background: currentColor;
  position: absolute;
  left: 0;
  top: 0;
}

.nav__menu-button::after {
  content: "";
  bottom: 0;
  top: unset;
}

.nav__links {
  list-style: none;
  display: flex;
  color: white;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-height: 9.2rem;
  overflow: hidden;
  flex: 0 99999999 auto;
}

.nav--light .nav__links .nav__link,
.nav--white .nav__links .nav__link {
  color: var(--primary-10);
}

.nav__ctas {
  display: flex;
  flex-flow: row;
  height: 100%;
  list-style: none;
}

.nav__ctas .nav__social {
  margin-right: 1.8rem;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: flex-end;
  flex: 0 0 auto;
  padding-left: 0.8rem;
}

.nav__ctas .nav__social .social-icons {
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap-reverse;
  height: 100%;
  padding: 1.4rem 0;
  box-sizing: border-box;
  flex: 0 0 auto;
}

.nav__item {
  position: relative;
  cursor: pointer;
}

.nav__sub-menu {
  display: none !important;
  position: absolute;
  top: 100%;
  left: 1.2rem;
  max-width: 32rem;
  z-index: 999;
  background: var(--matte);
  padding: 1.8rem;
  box-shadow: var(--elevation-4);
  list-style: none;
  text-align: left;
  border-radius: 0.4rem;
  grid-gap: 0.4rem;
}

.nav__item:hover .nav__sub-menu {
  display: grid;
}

.nav__sub-menu .nav__sub-link {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.8rem 0.8rem;
  display: flex;
  transition: background-color .18s ease-in-out;
  border-radius: 0.4rem;
}

.nav__sub-menu .nav__sub-link:hover {
  background: var(--primary-1);
  text-decoration: none;
}

.nav__item .nav__sub-menu .nav__sub-link.active {
  background: var(--primary-5);
  color: white;
}

.nav__link, .nav__link:visited {
  margin-left: 2.4rem;
  line-height: 4.5rem;
  font-size: var(--font-3);
  color: white;
  font-weight: 400;
  white-space: pre;
}

.nav__link.active {
  text-decoration: underline;
}

.nav__logo {

}

.nav__social .social-icons {
  color: white;
}

.nav__donate .donate {
  background-color: var(--primary);
  color: var(--primary-text) !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-3);
  font-weight: 800;
  font-family: var(--sans);
  margin-right: -1.9rem;
  border-radius: 0;
}

.nav__get-involved .button {
  background: transparent;
  color: var(--primary);
  font-size: var(--font-1);
  padding: 0 2.4rem;
}

.logo {
  text-decoration: none;
  margin: unset;
}

.logo__image {
  height: 6.4rem;
  max-width: 17.2rem;
  object-fit: contain;
}

.nav__logo--overlay {
  align-self: flex-start;
  margin-top: 0.8rem;
}

.nav__logo--overlay .logo__image {
  height: calc(6.4rem * 2);
  max-width: unset;
}


.universe--hidden-nav .nav__links,
.universe--hidden-nav .nav__ctas
{
  display: none;
}

.universe--hidden-nav {
  padding-top: 0;
}

.universe--hidden-nav .nav {
  justify-content: center;
  height: 5.2rem;
  width: 100vh;
  left: 50%;
  transform: translateX(-50%);
  padding: 1.8rem;
  text-align: center;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.universe--hidden-nav .nav::after {
  background: var(--nav-color);
}

.universe--hidden-nav .logo__image {
  height: 3.6rem;
}

@media (max-width: 850px) {
  #body {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate3d(0,0,0);

    -webkit-overflow-scrolling: touch;

    -webkit-scroll-snap-stop: always;
    --ms-scroll-snap-stop: always;
    scroll-snap-stop: always;

    -webkit-scroll-snap-type: mandatory;
    -ms-scroll-snap-type: mandatory;
    scroll-snap-type: mandatory;

    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;

    /* older spec implementation */
    -webkit-scroll-snap-destination: 0% 100%;
    -ms-scroll-snap-destination: 0% 100%;
    scroll-snap-destination: 0% 100%;

    -webkit-scroll-snap-points-x: repeat(100%);
    -ms-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%);
  }

  .nav__sub-menu {
    display: none !important;
  }

  .main {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 0 0 auto;
    scroll-snap-align: start;
    position: relative;
  }

  .nav {
    position: relative;
    width: calc(100% - 4.8rem);
    scroll-snap-align: start;
    height: 100%;
    flex: 0 0 auto;
    flex-flow: column;
    align-items: flex-start;
    padding: 5.2rem 1.8rem;
    z-index: 999;
    box-sizing: border-box;
  }

  .nav::after, .nav::before { display: none; }

  .nav__links {
    flex-flow: column;
    text-align: left;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    gap: 0.8rem;
    margin: 0 auto;
    flex-grow: 1;
    flex-shrink: unset;
  }

  .nav__links:first-of-type {
    height: 100%;
    max-height: unset;
  }

  .nav__links:last-of-type {
    height: fit-content;
    align-items: center;
  }

  .nav__ctas .nav__social { margin: 0.8rem -0.8rem; }

  .nav__ctas {
    height: fit-content;
    flex-flow: column;
    width: 100%;
  }

  .nav__link, .nav__link:visited {
    font-weight: bold;
    font-size: var(--font-3);
  }

  .nav__donate {
    width: 100%;
  }

  .nav__donate .donate {
    width: 100%;
    height: 5.2rem;
  }

  .nav__social {
    margin-bottom: var(--font-1);
  }

  .nav__ctas .nav__social .social-icons {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
  }

  .nav__menu-button {
    display: block;
    color: var(--primary-1);
    background: var(--primary-9);
    box-shadow: -16px 0 0 4px var(--primary-9), 0px 0 0 4px var(--primary-9);
    border-radius: 0 0 0.4rem 0;
  }

  .section.home__about {
    min-height: 0;
    margin: 0;
  }

  .section.home__about .button {
    margin: 0 auto;
  }

  .section.home__endorsements .endorsement {
    text-align: center;
  }

  .section.home__endorsements .button {
    margin: 0 auto;
  }

  .section.home__issues .issues {
    width: fit-content;
    margin: 1.8rem auto;
  }
  .section.home__issues .issue {
    text-align: center;
  }
  .section.home__issues .button {
    margin: 0 auto;
  }

  .section {
    padding: 2.4rem;
  }

  .home__header {
    min-height: 75vh;
  }

  .home__about .home__header-image {
    display: none;
  }
}

@media (max-width: 850px) {
  .universe--hidden-nav .nav {
    position: fixed;
  }
  .universe--hidden-nav .nav__menu-button {
    display: none;
  }
}
