.social-icons {
  list-style: none;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto auto auto;
  gap: 0.8rem;
}

.social-icons__link {
  font-size: 0;
  color: inherit;
}

.social-icons__icon {
  color: transparent;
  font-size: 0;
  overflow: hidden;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0.4rem;
  box-sizing: content-box;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.social-icons__svg {
  width: 100%;
  height: 100%;
  color: var(--primary-2);
}
