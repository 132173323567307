.button,
.quill-button {
  display: inline-block;
  padding: 0 3.8rem;
  font-size: var(--font-3);
  line-height: 5.2rem;
  font-weight: 800;
  text-decoration: none;
  color: var(--cta-text);
  background: var(--cta);
  border-radius: 2px;
  border: none;
  cursor: pointer;
  transition: opacity .15s;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
}

.button:visited,
.quill-button:visited {
  color: var(--cta-text);
}

.button.login,
.quill-button.login {
  margin-top: 2rem;
}

.button:hover,
.button:active,
.quill-button:hover,
.quill-button:active {
  color: white;
  text-decoration: underline;
}

.button[data-format=full], .quill-button[data-format=full] {
  display: block;
}

.button[data-format=left], .quill-button[data-format=left] {
  display: block;
  margin-left: 0px;
  margin-right: auto;
  width: fit-content;
}

.button[data-format=right], .quill-button[data-format=right] {
  display: block;
  margin-right: 0px;
  margin-left: auto;
  width: fit-content;
}

.button[data-format=center], .quill-button[data-format=center] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

@media (max-width: 850px) {
  .button, .quill-button {
    width: 100% !important;
    max-width: unset !important;
  }
}