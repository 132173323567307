.page__body {
  max-width: 82rem;
  display: inline-block;
  margin: 0 auto;
  padding-bottom: 2.8rem;
  background: var(--matte);
}

.page__body:empty {
  display: none;
}

@media (max-width: 850px) {
  .page__body {
    padding: 1.8rem;
    background: var(--matte) !important;
  }
}
