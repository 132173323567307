.wysiwyg {

  text-align: left;
  font-family: var(--sans), 'Montserrat', 'Lato' sans-serif;
  display: inline-block;
  width: 100%;
}

.wysiwyg::after {
  content: "";
  display: table;
  clear: both;
}

.wysiwyg *:last-child {
  margin-bottom: 0;
}

.ql-font-sans {
  font-family: var(--sans), 'Montserrat', 'Lato' sans-serif;
}
.ql-font-serif {
  font-family: var(--serif), 'Georgia', 'Times New Roman', serif;
}

.ql-font-monospace {
  font-family: var(--mono), 'Monaco', 'Courier New', monospace;
}

.wysiwyg p {
  font-size: var(--font-3);
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 1.2em;
}

.wysiwyg h1 {
  font-size: var(--font-7);
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 0.4em;
}

.wysiwyg h2 {
  font-size: var(--font-6);
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 0.4em;
}

.wysiwyg h3 {
  font-size: var(--font-5);
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 0.4em;
}

.wysiwyg h4 {
  font-size: var(--font-4);
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 0.4em;
}

.wysiwyg h5 {
  font-size: var(--font-3);
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 0.4em;
}

.wysiwyg h6 {
  font-size: var(--font-2);
  line-height: 1.2;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0.2em;
}

.wysiwyg hr {
  display: block;
  width: 4.8rem;
  margin: 1.2em auto;
  border: 0px solid var(--primary);
  border-bottom-width: 1px;
}

.wysiwyg blockquote {
  color: var(--gray-8);
  font-size: var(--font-4);
  margin: 2.8rem auto 1.8rem;
  max-width: calc(100% - 4.8rem);
  padding: 0.8rem 1.2rem 0.8rem 4.8rem;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 0;
}

.wysiwyg blockquote:before {
  color: var(--primary-1);
  content: "\f10d";
  font-family: "fontawesome";
  font-size: var(--font-7);
  left: 1.4rem;
  margin-right: 0.8rem;
  position: absolute;
  top: -1.8rem;
  z-index: -1;
}

.wysiwyg.wysiwyg--reader p {
  line-height: 1.4;
}
.wysiwyg.wysiwyg--reader blockquote {
  font-size: var(--font-4);
  line-height: 1.4;
}

.wysiwyg ul {
  
}

.wysiwyg:last-child {
  margin-bottom: 0;
}

.quill-button {
  margin-bottom: 1.2em;
}

.quill-image, .quill-video {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  outline: none;
  position: relative;
  margin-bottom: 1.2em;
}

.quill-image img {
  width: 100%;
}

.quill-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.quill-video .quill-video__wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.quill-image figcaption, .quill-video figcaption {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 1.2;
  margin: 0.4rem 0 0;
  padding: 0.4rem 0 0;
  outline: none;
  color: var(--gray);
  font-size: var(--font-1);
}

.quill-image figcaption:empty, .quill-video figcaption:empty { display: none; }

.quill-image a {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.quill-image[data-format=full], .quill-video[data-format=full] {
  width: 100%;
  margin: 0 0 12px;
}

.quill-image[data-format=center], .quill-video[data-format=center] {
  width: 100%;
  margin: 0 auto 12px;
  max-width: 50%;
  min-width: 32rem;
}

.quill-image[data-format=left], .quill-video[data-format=left] {
  width: calc(60% - 12px);
  float: left;
  margin: 0 1.8rem 1.8rem -10%;
}

.quill-image[data-format=right], .quill-video[data-format=right] {
  width: calc(60% - 12px);
  float: right;
  margin: 0 -10% 12px 12px;
}

.ql-align-left {
  text-align: left;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

/* To Ensure Editor UI Doesn't Accidentally Get Injected */
.quill-video__format, .quill-video__alt, .quill-video__link, .quill-video__caption-edit {
  display: none !important;
}

.quill-button__format, .quill-button__href-input, .quill-button__text-input {
  display: none !important;
}

.quill-image__format, .quill-image__alt, .quill-image__link, .quill-image__caption-edit, .quill-image input[type=file] {
  display: none !important;
}
