
.wysiwyg p,
.wysiwyg ol,
.wysiwyg ul,
.wysiwyg pre,
.wysiwyg blockquote,
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.wysiwyg ol,
.wysiwyg ul {
  padding: 0;
  margin: 0 0 var(--font-3);
  font-size: inherit;
  list-style: none;
}
.wysiwyg ol > li,
.wysiwyg ul > li {
  list-style-type: none;
  margin: 0 0 var(--font-0);
}
.wysiwyg ul > li::before {
  content: '\f105';
  font-size: var(--font-2);
  top: 0;
  font-family: "fontawesome";
  position: relative;
  left: 0.2rem;
}
.wysiwyg ul[data-checked=true],
.wysiwyg ul[data-checked=false] {
  pointer-events: none;
}
.wysiwyg ul[data-checked=true] > li *,
.wysiwyg ul[data-checked=false] > li * {
  pointer-events: all;
}
.wysiwyg ul[data-checked=true] > li::before,
.wysiwyg ul[data-checked=false] > li::before {
  cursor: pointer;
  pointer-events: all;
}

.wysiwyg ul[data-checked=true] > li::before { content: '\2611'; }
.wysiwyg ul[data-checked=false] > li::before { content: '\2610'; }

.wysiwyg li::before {
  display: inline-flex;
  white-space: nowrap;
  content: "•";
  font-size: inherit;
  line-height: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 1.2rem;
  width: 1.2rem;
}

.wysiwyg li:not(.ql-direction-rtl)::before {
  margin-left: -1.8rem;
  margin-right: 0.6rem;
  text-align: right;
}

.wysiwyg li.ql-direction-rtl::before {
  margin-right: -1.8rem;
  margin-left: 0.6rem;
}

.wysiwyg ol li:not(.ql-direction-rtl),
.wysiwyg ul li:not(.ql-direction-rtl) {
  padding-left: var(--font-3);
}

.wysiwyg ol li.ql-direction-rtl,
.wysiwyg ul li.ql-direction-rtl {
  padding-right: var(--font-3);
}

.wysiwyg ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.wysiwyg ol li:before {
  content: counter(list-0, decimal) '. ';
  font-family: var(--serif);
  font-variant-numeric: tabular-nums;
}

.wysiwyg ol li.ql-indent-1 { counter-increment: list-1; }
.wysiwyg ol li.ql-indent-1:before { content: counter(list-1, lower-alpha) '. '; }
.wysiwyg ol li.ql-indent-1 { counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.wysiwyg ol li.ql-indent-2 { counter-increment: list-2; }
.wysiwyg ol li.ql-indent-2:before { content: counter(list-2, lower-roman) '. '; }
.wysiwyg ol li.ql-indent-2 { counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.wysiwyg ol li.ql-indent-3 { counter-increment: list-3; }
.wysiwyg ol li.ql-indent-3:before { content: counter(list-3, decimal) '. '; }
.wysiwyg ol li.ql-indent-3 { counter-reset: list-4 list-5 list-6 list-7 list-8 list-9; }

.wysiwyg ol li.ql-indent-4 { counter-increment: list-4; }
.wysiwyg ol li.ql-indent-4:before { content: counter(list-4, lower-alpha) '. '; }
.wysiwyg ol li.ql-indent-4 { counter-reset: list-5 list-6 list-7 list-8 list-9; }

.wysiwyg ol li.ql-indent-5 { counter-increment: list-5; }
.wysiwyg ol li.ql-indent-5:before { content: counter(list-5, lower-roman) '. '; }
.wysiwyg ol li.ql-indent-5 { counter-reset: list-6 list-7 list-8 list-9; }

.wysiwyg ol li.ql-indent-6 { counter-increment: list-6; }
.wysiwyg ol li.ql-indent-6:before { content: counter(list-6, decimal) '. '; }
.wysiwyg ol li.ql-indent-6 { counter-reset: list-7 list-8 list-9; }

.wysiwyg ol li.ql-indent-7 { counter-increment: list-7; }
.wysiwyg ol li.ql-indent-7:before { content: counter(list-7, lower-alpha) '. '; }
.wysiwyg ol li.ql-indent-7 { counter-reset: list-8 list-9; }

.wysiwyg ol li.ql-indent-8 { counter-increment: list-8; }
.wysiwyg ol li.ql-indent-8:before { content: counter(list-8, lower-roman) '. '; }
.wysiwyg ol li.ql-indent-8 { counter-reset: list-9; }

.wysiwyg ol li.ql-indent-9 { counter-increment: list-9; }
.wysiwyg ol li.ql-indent-9:before { content: counter(list-9, decimal) '. '; }

.wysiwyg ol:not(.ql-direction-rtl) li { padding-right: 0; padding-left: var(--wysiwyg-list-indent, 1.6em); }
.wysiwyg ul:not(.ql-direction-rtl) li { padding-right: 0; padding-left: calc(var(--wysiwyg-list-indent, 1.6em) - 0.2em); }
.wysiwyg ol.ql-direction-rtl.ql-align-right li { padding-left: 0; padding-right: var(--wysiwyg-list-indent, 1.6em); }
.wysiwyg ul.ql-direction-rtl.ql-align-right li { padding-left: 0; padding-right: calc(var(--wysiwyg-list-indent, 1.6em) - 0.2em); }

.wysiwyg .ql-indent-1 { --wysiwyg-list-indent: 2.4em; }
.wysiwyg .ql-indent-2 { --wysiwyg-list-indent: 3em; }
.wysiwyg .ql-indent-3 { --wysiwyg-list-indent: 3.8em; }
.wysiwyg .ql-indent-4 { --wysiwyg-list-indent: 4.6em; }
.wysiwyg .ql-indent-5 { --wysiwyg-list-indent: 5.4em; }
.wysiwyg .ql-indent-6 { --wysiwyg-list-indent: 6em; }
.wysiwyg .ql-indent-7 { --wysiwyg-list-indent: 6.8em; }
.wysiwyg .ql-indent-8 { --wysiwyg-list-indent: 7.6em; }
.wysiwyg .ql-indent-9 { --wysiwyg-list-indent: 8.4em; }

.wysiwyg ul li::before {
  content: '\f105';
  font-size: var(--font-2);
  top: 0;
  font-family: "fontawesome";
  position: relative;
  left: 0.2rem;
}
