[neutrino-preview] .home__header::after {
  background: radial-gradient(var(--cta-2) 15%, transparent 16%), linear-gradient(45deg, transparent 49%, var(--cta-2) 49% 51%, transparent 51%), linear-gradient(-45deg, transparent 49%, var(--cta-2) 49% 51%, transparent 51%);
  background-size: 3em 3em;
  background-color: #ffffff;
  opacity: 1;
  z-index: -2;
}

[neutrino-preview] .home__header::after,
[neutrino-preview] .volunteer__body,
[neutrino-preview] .petition {
  background: radial-gradient(var(--primary-2) 15%, transparent 16%), linear-gradient(45deg, transparent 49%, var(--primary-2) 49% 51%, transparent 51%), linear-gradient(-45deg, transparent 49%, var(--primary-2) 49% 51%, transparent 51%);
  background: linear-gradient(45deg, #ffffff 20%, transparent 20%, transparent 80%, #ffffff 80%),linear-gradient(135deg, #ffffff 40%, var(--primary-2) 40%, var(--primary-2) 60%, var(--primary-2) 60%, #ffffff 60%) , linear-gradient(45deg, #ffffff 20%, var(--primary-2) 20%, var(--primary-2) 80%, #ffffff 80%);
  background: linear-gradient(45deg,transparent 34%, var(--primary-2) 35%, var(--primary-2) 40%, transparent 41%, transparent 59%, var(--primary-2)  60%, var(--primary-2) 65%, transparent 66%),linear-gradient(135deg,transparent 34%, var(--primary-2) 35%, var(--primary-2) 40%, transparent 41%, transparent 59%, var(--primary-2)  60%, var(--primary-2) 65%, transparent 66%);
  background: radial-gradient(circle at top left,transparent 9%, var(--primary-2) 10% ,var(--primary-2) 15% , transparent 16%) , radial-gradient(circle at bottom left,transparent 9%, var(--primary-2) 10% ,var(--primary-2) 15% , transparent 16%), radial-gradient(circle at top right ,transparent 9%, var(--primary-2) 10% ,var(--primary-2) 15% , transparent 16%) , radial-gradient(circle at bottom right,transparent 9%, var(--primary-2) 10% ,var(--primary-2) 15% , transparent 16%),radial-gradient(circle, transparent 25%, #ffffff  26%),linear-gradient(45deg, transparent 46%, var(--primary-2) 47%, var(--primary-2) 52%, transparent 53%), linear-gradient(135deg, transparent 46%, var(--primary-2) 47%, var(--primary-2) 52%, transparent 53%);
  background-size: 8.2rem 8.2rem;
  background-color: var(--primary-0);
  opacity: 1;
  z-index: -2;
}

[neutrino-preview] .petition__title::before {
  content: "Sign Our Petition";
}

[neutrino-preview] .header__title::before {
  content: "Home Page";
  padding-left: 4.2rem;
}

[neutrino-preview] .header__content h1::before {
  content: "Page Title"
}

[neutrino-preview] .endorsements__body::before {
  content: "Endorsements";
  font-size: 4.8rem;
  --size: 16rem;
  width: var(--size);
  height: var(--size);
  margin-bottom: calc(var(--size) + 6.2rem + 8.2rem);
  border-radius: 0.8rem;
  background-color: transparent;
  margin-left: 0.8rem;
  box-shadow: 
    inset 0 -7.8rem 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 0) 8.2rem 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 1) 8.2rem 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 2) 8.2rem 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 3) 8.2rem 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 4) 8.2rem 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 0) calc((var(--size) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 1) calc((var(--size) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 2) calc((var(--size) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 3) calc((var(--size) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--size) + 1.8rem) * 4) calc((var(--size) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1);
}


[neutrino-preview] .issues__body::before {
  content: "Issues";
  font-size: 4.8rem;
  display: block;
  text-align: left;
  --width: 38rem;
  --height: 16rem;
  width: var(--width);
  height: var(--height);
  margin-bottom: calc(var(--height) + 1.8rem + 8.2rem);
  border-radius: 0.8rem;
  background-color: transparent;
  box-shadow: 
    inset 0 -7.8rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 0) 8.2rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 1) 8.2rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 2) 8.2rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 3) 8.2rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 4) 8.2rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 0) calc((var(--height) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 1) calc((var(--height) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 2) calc((var(--height) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 3) calc((var(--height) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 4) calc((var(--height) + 1.8rem + 8.2rem) * 1) 0 0 var(--primary-1);
}


[neutrino-preview] .gallery__body::before {
  content: "";
  font-size: 4.8rem;
  display: block;
  text-align: left;
  --width: 18rem;
  --height: 18rem;
  width: var(--width);
  height: var(--height);
  transform: translateX(9.8rem);
  margin-bottom: calc(var(--height) + 1.8rem + 8.2rem);
  border-radius: 0.8rem;
  background-color: transparent;
  top: -5.2rem;
  position: relative;
  box-shadow: 
    inset 0 -7.8rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 0) 10rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 1) 10rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 2) 10rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 3) 10rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 4) 10rem 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 0) calc((var(--height) + 1.8rem + 10rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 1) calc((var(--height) + 1.8rem + 10rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 2) calc((var(--height) + 1.8rem + 10rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 3) calc((var(--height) + 1.8rem + 10rem) * 1) 0 0 var(--primary-1),
    calc((var(--width) + 1.8rem) * 4) calc((var(--height) + 1.8rem + 10rem) * 1) 0 0 var(--primary-1);
}

[neutrino-preview] .page__body::before,
[neutrino-preview] .petition__body::before {
  content: "Headline";
  font-size: 4.8rem;
  font-weight: bold;
  margin-bottom: 1.8rem;
}

[neutrino-preview] .petition__body {
  background-color: white;
  padding: 0.8rem;
}

[neutrino-preview] .petition__body:empty {
  opacity: 1;
  height: fit-content;
}

[neutrino-preview] .page__body::after {
  content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis rhoncus egestas orci vel pharetra. Integer vulputate odio libero, non lacinia ligula mollis eu. Donec vitae orci metus. \A \A Nulla egestas nisi tellus, id varius orci malesuada ut. Mauris sit amet magna consectetur, varius felis et, vestibulum sapien. Nam volutpat arcu eget purus vulputate, at ultricies ipsum venenatis. Pellentesque ac ipsum ac nisl imperdiet tempor ac tempus justo. Nunc ac metus id justo varius tincidunt. Cras blandit ultricies iaculis. Praesent in tincidunt felis, at elementum metus. Sed cursus metus at mi faucibus, et tincidunt justo dignissim. Sed efficitur, ex sit amet sagittis maximus, risus velit dapibus nisi, ac elementum erat diam eu tellus.";
  font-size: 2.4rem;
  position: static;
  margin: auto;
  transform: none;
  max-width: 100%;
  white-space: pre-line;
}

[neutrino-preview] .petition__body::after {
  content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. \A \A Duis rhoncus egestas orci vel pharetra. Integer vulputate odio libero, non lacinia ligula mollis eu. Donec vitae orci metus. Nulla egestas nisi tellus, id varius orci malesuada ut.";
  font-size: 2.4rem;
  position: static;
  margin: auto;
  transform: none;
  max-width: 100%;
  white-space: pre-line;
  margin-bottom: 2.4rem;
}

[neutrino-preview] .blog__collections-title::before {
  content: "Blog Title";
}

[neutrino-preview] .volunteer__form {
  border: 3px solid var(--primary-5);
}

[neutrino-preview] .blog__section .post__empty,
[neutrino-preview] .blog__section .posts::before,
[neutrino-preview] .blog__section .posts::after {
  content: "Article 1";
  display: block;
  position: static;
  transform: none;
  grid-row: unset;
  margin: 0;
  background: var(--primary-1);
  height: 22rem;
  grid-column: unset;
  opacity: 1;
  color: transparent;
  border-radius: 1.8rem;
}

[neutrino-preview] .blog__section .post__empty {
  height: 9.8rem;
  box-shadow: 0 12.4rem 0 0 var(--primary-1);
}

[neutrino-preview] .blog__collections::after {
  content: "Articles";
  padding: 0.4rem 1.2rem;
  border: 0px solid black;
  border-width: 1px 0;
  font-weight: bold;
  color: var(--gray-9);
  margin-top: -5px;
  font-size: 2rem;
}

[neutrino-preview] .blog__section .posts::after {
  content: "Article 2";
}