.contribute-cta {
  max-width: var(--site-width);
  margin: 4.8rem auto;
  background-color: var(--matte);
  overflow: hidden;
}

.contribute-cta__list {
  display: grid;
  gap: 0 1.8rem;
  list-style: none;
  left: 50%;
  position: relative;
  transform: translateX(calc((((max(100%, 980px) / 7) * (var(--count) + 2)) * -1) / 2));
  grid-template-columns: repeat(auto-fit, calc((max(100%, 980px) / 7) - ((1.8rem * 6) / 7)));
}

.contribute-cta__list h1 {
  font-size: var(--font-4);
}

.contribute-cta__list li {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-8);
  margin: 0.9rem 0;
}

.contribute-cta__list li:first-of-type {
  grid-row: 1 / 999;
  align-items: center;
}

a.contribute-cta__amount {
  border: 4px solid var(--cta);
  color: var(--primary-8) !important;
  width: 100%;
  padding: 1.8rem 4.2rem;
  font-size: var(--font-4);
  line-height: 1;
  font-weight: bold;
  background: transparent;
  display: flex;
  justify-content: center;
  font-family: var(--serif);
  font-variant-numeric: tabular-nums;
}

@media (max-width: 850px) {
  .contribute-cta__list {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0 0.8rem;
    left: 0px;
    transform: none;
  }
  .contribute-cta__list li {
    margin: 0.4rem 0;
  }
  .contribute-cta__list li:first-of-type {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 1.8rem;
  }
}
