.petition {
  min-height: calc(100vh - 19.8rem);
  max-width: 118rem;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 3.2rem;
  position: relative;
}

.petition__container {
  display: flex;
  width: 100%;
}

.petition__form {
  width: 52rem;
  padding: 2.8rem;
  background: var(--primary);
  text-align: left;
}

.petition__title {
  color: white;
  line-height: 1;
  text-align: left;
  font-size: var(--font-6);
  margin-bottom: 1.8rem;
}

.petition__subtitle {
  color: white;
  font-weight: 600;
  margin-bottom: 2.4rem;
  text-align: left;
  line-height: 1;
  font-size: var(--font-3);
}

.petition__form-body {
  width: 100%;
  word-break: break-word;
  color: white;
  margin-bottom: 1.8rem;
}

.petition__disclaimer {
  color: white;
  opacity: .85;
  font-size: var(--font-1);
  text-align: center;
  max-width: 28rem;
  margin: 0.8rem auto 0;
}

.petition__image {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: calc(100% + 5.5rem);
  object-fit: cover;
  object-position: center;
  z-index: -1;
  transform: translateX(-50%);
}

.petition-form__section {
  margin-bottom: 1.2rem;
  display: flex;
  flex-flow: row;
}

.petition-form__section input {
  border-radius: 4px;
  border: 0px solid transparent !important;
}

.petition__form input[type=submit] {
  margin-left: auto;
  display: block;
  width: 100%;
}


.petition-form__section input + input {
  margin-left: 0.8rem;
}

.petition__body {
  width: calc(100% - 52rem - 2.4rem);
  margin-right: 2.4rem;
  background: white;
  height: 100%;
  padding: 3.2rem;
  text-align: left;
}

.petition__body:empty { opacity: 0; height: 1px; padding: 0; }

.petition__thank-you {
  display: none;
}

.petition__thank-you:target,
.petition__thank-you--visible {
  display: block;
  margin: auto;
  width: 52rem;
  position: relative;
  top: -10vh;
  padding: 2.8rem;
  background: var(--primary);
  text-align: left;
  color: white;
}

.petition__thank-you:target + .petition__container,
.petition__thank-you--visible + .petition__container {
  display: none;
}


@media (max-width: 1024px) {
  .petition__form {
    width: 42rem;
  }

  .petition__body {
    width: calc(100% - 42rem - 2.4rem);
  }
}


@media (max-width: 820px) {

  .petition__body, .petition__form {
    width: 100%;
    max-width: calc(100% - 4.2rem);
    margin: 0;
    margin-bottom: 1.2rem;
  }

  .petition__body {
    background: var(--matte); 
    border-radius: 0 0 0.8rem 0.8rem;
  }
/* 
  .petition::before {
    content: "";
    position: absolute;
    top: 33vh;
    height: 33vh;
    width: 100%;
    left: 0;
    background-image: linear-gradient(transparent, var(--gray-0));
    z-index: -1;
  } */

  .petition__form {
    margin-top: calc(33vh - 2.8rem);
    margin-bottom: 0px;
    border-radius: 0.8rem 0.8rem 0 0;
    box-shadow: 0 -0.2rem 2.8rem 0 rgba(0, 0, 0, .33), 0 -1px 0 0 var(--primary-5);
  }

  .petition__image {
    height: calc(33vh + 4rem);
    z-index: -2;
    position: fixed;
    left: calc(100% - 4.8rem);
    top: 0px;
    transform: unset;
  }

  .petition__container {
    flex-flow: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.8rem;
  }
  .petition__thank-you:target,
  .petition__thank-you--visible {
    position: absolute;
    max-width: calc(100% - 1.6rem);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 33vh auto 0;
    box-shadow: 
      -50vw 4.8rem 0 10px var(--primary-9),
      -50vw 14.8rem 0 10px var(--primary-9),
      -50vw 28rem 0 10px var(--primary-9),
      50vw 4.8rem 0 10px var(--primary-9),
      50vw 14.8rem 0 10px var(--primary-9),
      50vw 28rem 0 10px var(--primary-9),
      0 4.8rem 0 10px var(--primary-9),
      0 14.8rem 0 10px var(--primary-9),
      0 28rem 0 10px var(--primary-9);
  }
}