.footer {
  margin-top: 0;
  position: relative;
  padding-top: 5.2rem;
  z-index: 1;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  background: var(--primary-9);
  z-index: -1;
  box-shadow: 
    0 100px 0 0 var(--primary-9), 0 200px 0 0 var(--primary-9), 0 100px 0 0 var(--primary-9), 0 300px 0 0 var(--primary-9), 0 400px 0 0 var(--primary-9), 0 500px 0 0 var(--primary-9), 0 600px 0 0 var(--primary-9), 0 100px 0 0 var(--primary-9), 0 700px 0 0 var(--primary-9), 0 100px 0 0 var(--primary-9), 0 600px 0 0 var(--primary-9), 0 800px 0 0 var(--primary-9), 0 900px 0 0 var(--primary-9), 0 1000px 0 0 var(--primary-9),
    -100px 0 0 0 var(--primary-9), 200px 0 0 0 var(--primary-9), 100px 0 0 0 var(--primary-9), 300px 0 0 0 var(--primary-9), 400px 0 0 0 var(--primary-9), 500px 0 0 0 var(--primary-9), 600px 0 0 0 var(--primary-9), 100px 0 0 0 var(--primary-9), 700px 0 0 0 var(--primary-9), 100px 0 0 0 var(--primary-9), 600px 0 0 0 var(--primary-9), 800px 0 0 0 var(--primary-9), 900px 0 0 0 var(--primary-9), 1000px 0 0 0 var(--primary-9);
}

.footer .email-form {
  gap: 0.8rem;
}

.footer .email-form input {
  margin-right: 0;
  border: none;
}

.footer__universe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: var(--site-width);
  margin: 0 auto;
  padding: 3.2rem 0;
  border-top: 1px solid var(--light-gray);
  display: none;
}

.footer__universe-links {
  list-style: none;
  display: flex;
}

.footer__universe-link {
  margin-left: 2.4rem;
  line-height: 4.5rem;
}

.footer__universe-link.active {
  text-decoration: underline;
}

.footer__nav {
  margin-bottom: 3.2rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--site-width);
  margin: 0 auto;
  list-style: none;
  padding: 3.2rem 0;
  gap: 1.8rem;
}

.footer__nav .nav__social, .nav__social li {
  list-style: none;
}

.footer__mailchimp {
  width: 100%;
}

.footer__donate {
  grid-row: 1;
  grid-column: 3;
}

.footer__donate a { display: block; }

.footer__social {
  grid-column: 3;
  grid-row: 2;
  text-align: left;
}

.footer__disclosures {
  color: white;
  border: 4px solid currentColor;
  padding: 1.2rem 4.8rem;
  text-align: center;
  width: fit-content;
  max-width: 62rem;
}

.footer__links {
  display: flex;
  gap: 1.8rem;
  margin: 1.8rem 0;
  list-style: none;
}

.footer__link a {
  color: white !important;
  font-size: var(--font-2);
  text-transform: uppercase;
}

.footer__social ul {
  display: flex;
  flex-wrap: wrap;
}

.footer__social li {
  margin-bottom: 1.2rem;
}

.footer .social-icons {
  margin-bottom: 1.8rem;
  grid-template-columns: auto auto auto auto auto;
}

.footer__social li.nav__social--description, .footer__social li.nav__social--email {
  font-weight: bold;
  width: 100%;
  flex-grow: 1;
  min-width: 100%;
}

.footer .nav__social--email {
  color: var(--primary-2);
  text-decoration: underline;
}

.footer .email-form__label {
  color: white;
}

.footer__social .nav__social {
  color: white;
  display: flex;
  flex-wrap: wrap !important;
  flex-flow: row;
}

.footer .email-form input[type=submit] {
  background-color: var(--cta);
  color: var(--cta-text);
}

.footer .footer__logo {
  margin: 0;
}

.nav__social--description { 
  color: white; 
  margin: 0;
  position: relative;
  top: 1.2rem;
  font-size: var(--font-3);
}

.footer .logo__image {
  height: 8.2rem;
  max-width: 24rem;
  object-fit: contain;
}

.petition ~ .home__ctas--footer {
  display: none;
}

@media (max-width: 850px) {
  .footer {
    padding-top: 9.2rem;
  }
  .footer__links {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.8rem 2.4rem;
    width: 100%;
  }
  .footer__nav {
    grid-template-columns: 100%;
    text-align: center;
    padding: 1.2rem 1.2rem 5.8rem;
  }
  .footer__social {
    grid-column: 1;
    grid-row: 3;
    text-align: center;
  }
  .footer__social ul {
    justify-content: center;
  }
  .footer__mailchimp {
    grid-column: 1;
    grid-row: 2;
    text-align: center;
  }
  .footer__mailchimp .email-form__label {
    text-align: center;
  }
  .footer__social .nav__social {
    margin-bottom: 0;
  }
  .footer__donate {
    grid-column: 1;
    grid-row: 1;
    text-align: center;
  }
  .footer__disclosures {
    grid-column: 1;
    grid-row: 4;
    text-align: center;
    margin-bottom: 3.2rem !important;
  }
  .home__endorsements-title {
    text-align: center;
  }
  .home__endorsements .endorsements {
    padding: 0;
    border: 0;
  }
  .footer__universe {
    padding: 1.2rem 0 3.2rem;
  }
  .footer__universe, .footer__universe-links {
    flex-flow: column;
  }
  .footer__universe-link {
    margin: 0;
  }
  
}

.universe--hidden-nav .home__ctas--footer, 
.universe--hidden-nav .footer__links, 
.universe--hidden-nav .footer .email-form, 
.universe--hidden-nav .nav__social--description, 
.universe--hidden-nav .nav__social--email, 
.universe--hidden-nav .social-icons,
.universe--hidden-nav .footer__logo
{
  display: none;
}

.universe--hidden-nav .footer {
  padding: 0;
}
