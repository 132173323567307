.posts {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 2.4rem 3.2rem;
  grid-column-gap: 3.2rem;
  grid-row-gap: 2.4rem 2.4rem 2.4rem 5.8rem 2.4rem;
  grid-template-columns: repeat(3, calc(33.33% - (6.4rem / 3)));
  grid-template-rows: auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 5.2rem;
}

.posts.posts--empty::before {
  content: "No Posts";
  display: flex;
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
  height: 4.2rem;
  align-items: center;
  color: var(--gray-3);
}

.post {
  text-align: left;
  position: relative;
  /* height: 12.8rem; */
  padding-left: 10.8rem;
}

.post--image {
  height: 9rem;
}

.post--hidden {
  display: none;
}

.post--no-image {
  padding-left: 0 !important;
}

.post a { 
  color: var(--gray-9);
}
.post a:hover {
  text-decoration: none;
  cursor: pointer;
}
.post .post__image {
  height: 9rem;
  width: 9rem;
  object-fit: cover;
  position: absolute;
  left: 0;
  border-radius: 0.4rem;
}
.post .post__title {
  font-size: var(--font-3);
  line-height: 1.2;
}

.post a:hover .post__title {
  text-decoration: underline;
}

.post .post__timestamp {
  color: var(--gray-5);
  font-size: var(--font-1);
  font-weight: 40;
  position: relative;
  top: -4px;
}
.post__empty {
  margin: 3.2rem auto;
  grid-column: 1/4;
  padding: 0;
  opacity: 0.25;
}
.blog__header {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-bottom: 34vw;
  padding-top: 4.2rem;
}
.blog__header.blog__header--no-image {
  padding-bottom: 3.2rem;
}
.blog__collections-header {
  margin-bottom: 1.8rem;
}
.blog__collections-meta {
  text-transform: uppercase;
  color: var(--gray-8);
  font-size: var(--font-0);
  font-weight: bold;
  text-align: left;
  display: block;
}
.blog__header-image {
  position: absolute;
  width: 100vw;
  height: 34.25vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  margin: 0;
  object-fit: cover;
}
.blog__secondary-nav {
  width: 100%;
  text-align: left;
}
.blog__collections-title {
  margin-bottom: 1.8rem;
}
.blog__collections {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0.6rem 0;
}
.blog__collection {
  padding: 0.4rem 1.2rem;
  border: 0px solid black;
  border-width: 1px 0;
  font-weight: bold;
  color: var(--gray-9);
}
.blog__collection:target {
  background: var(--light-gray);
}
.blog__header .blog__title {
  font-size: var(--font-8);
  line-height: 1;
  font-weight: 800;
  margin-bottom: 0;
}
.blog__header .blog__subtitle {
  font-size: var(--font-5);
  line-height: 1.2;
  font-weight: 800;
  margin-bottom: 0.4rem;
  color: var(--gray-8);
}
.blog__timestamp {
  display: block;
  margin-bottom: 1.8rem;
  opacity: var(--gray-6);
  font-size: var(--font-2);
  font-weight: 400;
  color: var(--gray-6);
}

.blog__header, .blog__body {
  max-width: 92rem;
  display: block;
  margin: 0 auto;
}

.blog__body {
  display: inline-block;
}

.blog__section {
  max-width: var(--site-width);
  min-height: calc(100vh - 42rem);
}

.blog__more {
  padding-top: 4.2rem;
  border-top: 1px solid rgba(0, 0, 0, .15);
  margin-top: 4.2rem;
}

.post__title {
  line-height: 1;
  margin-bottom: 0.4rem;
  font-size: var(--font-4);
}

.post__subtitle {
  line-height: 1.2;
  font-size: var(--font-2);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0;
  height: auto;
  color: var(--gray-6);
}

.blog__section {
  max-width: var(--site-width);
  min-height: calc(100vh - 42rem);
  padding: 0 0.8rem;
}

@media (min-width: 780px) {
  .post__title {
    font-size: var(--font-3);
    line-height: 1;
    margin-bottom: 0.4rem;
  }

  .blog__section {
    padding: inherit;
  }

  .post__image {
    height: 9rem;
    width: 9rem;
  }

  .post__subtitle {
    line-height: 1.2;
    font-size: var(--font-2);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
    height: auto;
  }
  .post:first-of-type .post__image, .post:nth-of-type(2) .post__image {
    position: relative;
    width: 100%;
    height: 14.2rem;
    margin-bottom: 0;
 }
  .post:first-of-type {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 0;
    height: unset !important;
 }
  .post:nth-of-type(2) {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 0;
    height: unset !important;
  }
  
  .post:nth-of-type(2).post--no-image {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 0;
  }

  .post:nth-of-type(2):last-of-type {
    grid-column: 2 !important;
 }

  .post:first-of-type.post--no-image {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 0;
  }

  /* .post:nth-of-type(2).post--no-image, .post:nth-of-type(3), .post:nth-of-type(4), .post:nth-of-type(5) {
    height: 9rem;
    padding-left: 10.2rem;
 }
  .post:nth-of-type(2).post--no-image .post__image, .post:nth-of-type(3) .post__image, .post:nth-of-type(4) .post__image, .post:nth-of-type(5) .post__image {
    height: 9rem;
    width: 9rem;
 }
  .post:nth-of-type(2).post--no-image .post__title, .post:nth-of-type(3) .post__title, .post:nth-of-type(4) .post__title, .post:nth-of-type(5) .post__title {
    font-size: var(--font-3);
    line-height: 1.2;
 }
  .post:nth-of-type(2).post--no-image .post__subtitle, .post:nth-of-type(3) .post__subtitle, .post:nth-of-type(4) .post__subtitle, .post:nth-of-type(5) .post__subtitle {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
 } */

 /* .post:nth-of-type(2).post--no-image ~ .post:nth-of-type(6) {
    grid-column: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 0;
    height: 9rem;
    padding-left: 10.2rem;
 }
  .post:nth-of-type(2).post--no-image ~ .post:nth-of-type(6) .post__image {
    height: 9rem;
    width: 9rem;
  } */

  /* .post:nth-of-type(2).post--no-image ~ .post:nth-of-type(6) .post__title {
    font-size: var(--font-3);
    line-height: 1.2;
  } */

  /* .post:nth-of-type(2).post--no-image ~ .post:nth-of-type(6) .post__subtitle {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
  } */

}

@media (max-width: 1024px) {
  .posts {
    gap: 1.8rem;
    grid-template-columns: calc(50% - (1.8rem / 2)) calc(50% - (1.8rem / 2));
  }

  .post {
    height: unset !important;
  }

  .post:nth-of-type(1) {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 0;
  }

  .post:nth-of-type(2) {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 0;
  }

  .post:nth-of-type(2).post--no-image {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 0;
  }

  .post:not(:nth-of-type(1)):not(:nth-of-type(2)) {
    padding-left: 10.2rem;
  }
  .post:not(:nth-of-type(1)):not(:nth-of-type(2)) .post__image {
    height: 9rem;
    width: 9rem;
  }

  .post:not(:nth-of-type(1)):not(:nth-of-type(2)) .post__title {
    font-size: var(--font-3);
    line-height: 1.2;
  }

  .post:not(:nth-of-type(1)):not(:nth-of-type(2)) .post__subtitle {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
  }
}

@media (max-width: 780px) {
  .posts {
    grid-gap: 2.4rem;
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
  .post {
    grid-column: 1 !important;
    grid-row: auto !important;
    grid-row-start: unset !important;
    grid-row-end: unset !important;
  }

  .post {
    padding-left: 10.2rem !important;
  }

  .post.post--no-image {
    padding-left: 0rem !important;
  }

  .post .post__image {
    height: 9rem;
    width: 9rem;
  }

  .post .post__title {
    font-size: var(--font-3);
    line-height: 1.2;
  }

  .post .post__subtitle {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
  }
}
@media (max-width: 850px) {
  .blog__header {
    flex-flow: column;
    height: auto;
    min-height: 0;
    padding: 8.2rem 1.8rem 34vw;
  }
  .blog__body {
    padding: 1.8rem;
    background-color: var(--matte);
  }
  .blog__title {
    font-size: var(--font-6) !important;
    margin-bottom: 0.8rem !important;
  }
  .blog__subtitle {
    font-size: var(--font-4) !important;
  }
  .blog__timestamp {
    margin-bottom: 1.2rem;
  }
  .blog__collections-header {
    padding-top: 4.2rem;
    margin-bottom: 0px;
  }
}
