.home__about {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-bottom: 2.4rem;
  padding: 0;
  justify-content: center;
  align-items: flex-start;
}

.home__about.home__about--bold {
  --section-color: var(--cta);
  padding: 4.8rem 0;
  margin: 0px auto 0px;
}

.home__about--bold * {
  color: white !important
}

.home__about--bold .button, .home__about--bold .quill-button {
  background-color: var(--primary);
}

.home__about--bold .home__about-image {
  transform: rotate(-3deg);
  border: 1.8rem solid var(--cta-0);
  border-radius: 0px;
  box-shadow: var(--elevation-6), var(--elevation-6), var(--elevation-6);
}

.home__about-text {
  width: 50%;
  align-self: center;
}

.home__about-image {
  margin-left: 2.8rem;
  margin-right: 6.2rem;
  margin-bottom: 2.8rem;
  width: calc(50% - 10.4rem);
  height: calc((var(--site-width) / 2) - 10.4rem);
  object-fit: contain;
  border-radius: 0.4rem;
  box-shadow: -1.8rem 1.8rem 0 0 var(--cta);
}

.home__about-cta {
  margin: 2.4rem auto 0;
}

.home__endorsements {
  margin: 2.4rem auto;
  padding: 0 1.8rem 4.8rem;
}

.home__endorsements #for-id-only-desc {
  bottom: 0.8rem;
  right: 0.8rem;
}

.home__endorsements .endorsement {
  display: none;
  flex-flow: column;
  justify-content: flex-start;
}

.home__endorsements .endorsement--text {
  padding-bottom: 50%;
  justify-content: center;
}

.home__endorsements .endorsement:nth-of-type(1),
.home__endorsements .endorsement:nth-of-type(2),
.home__endorsements .endorsement:nth-of-type(3),
.home__endorsements .endorsement:nth-of-type(4),
.home__endorsements .endorsement:nth-of-type(5),
.home__endorsements .endorsement:nth-of-type(6) {
  display: flex;
}

.home__endorsements .endorsements {
  grid-template-columns: repeat(6, 18rem);
  width: fit-content;
  margin: 0 auto 2.4rem;
}

@media (max-width: 1148px) {
  .home__endorsements .endorsements {
    grid-template-columns: repeat(5, 18rem);
  }
  .home__endorsements .endorsement:nth-of-type(6) {
    display: none;
  }
}

@media (max-width: 912px) {
  .home__endorsements .endorsements {
    grid-template-columns: repeat(4, 18rem);
  }
  .home__endorsements .endorsement:nth-of-type(5) {
    display: none;
  }
}

@media (max-width: 700px) {
  #body .home__endorsements .endorsements {
    display: grid !important;
    grid-template-columns: repeat(3, 18rem) !important;
  }
  .home__endorsements .endorsement:nth-of-type(5),
  .home__endorsements .endorsement:nth-of-type(6) {
    display: block;
  }
}

@media (max-width: 500px) {
  #body .home__endorsements .endorsements {
    display: grid !important;
    grid-template-columns: repeat(2, 18rem) !important;
  }
  .home__endorsements .endorsement:nth-of-type(5),
  .home__endorsements .endorsement:nth-of-type(6) {
    display: block;
  }
}


.home__contributions {
  margin-bottom: 4.8rem;
  padding: 3.2rem 0.8rem;
  color: var(--primary-text-0);
  --section-color: var(--matte);
  margin-bottom: 0;
}

.home__issues {
  margin-bottom: 4.8rem;
  padding: 3.2rem 0;
  color: var(--primary-text);
  --section-color: var(--primary);
}

.home__issues .button {
  margin: 0 auto;
  width: fit-content;
  background: var(--cta);
  width: 100%;
  max-width: 32rem;
}

.home__issues .issues {
  margin: 3.2rem 0;
}

.home__issues .issue {
  display: none;
  display: flex;
  flex-flow: column;
}


.home__issues .issue:nth-of-type(1), .home__issues .issue:nth-of-type(2), .home__issues .issue:nth-of-type(3), .home__issues .issue:nth-of-type(4), .home__issues .issue:nth-of-type(5) {
  display: flex;
}

.home__issues-description {
  margin-bottom: 0;
  text-align: center;
  margin-top: 0.8rem;
  max-width: 82rem;
}

.home__volunteer {
  --section-color: var(--primary);
  background: var(--primary);
  position: relative;
  color: var(--primary-text);
  display: flex;
  align-items: flex-start;
  gap: 1.8rem;
  padding-top: 5.2rem;
  padding-bottom: 2.4rem;
  box-shadow: 50vw 24rem 0 0 var(--primary), -50vw 24rem 0 0 var(--primary);
  position: relative;
  z-index: 0;
}

.home__volunteer::before {
  content: "";
  width: 100vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50vw);
  height: auto;
  background: var(--primary);
  z-index: -1;
  top: 0;
  bottom: 0;
}

.home__volunteer-header {
  text-align: left;
  margin-left: 0;
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
  text-transform: uppercase;
  grid-column-end: 3;
  grid-column-start: 1;
}

.home__volunteer-image {
  top: 0px;
  right: 0px;
  width: 45%;
  height: auto;
  aspect-ratio: 1;
  bottom: 0px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  flex: 1 1 auto;
  height: 100%;
  object-fit: cover;
  object-position: center;
  box-shadow: -0.8rem 0.8rem 0 0 var(--cta);
}

.home__volunteer .volunteer__form {
  background: transparent;
  border: 0;
  padding-left: 0;
  margin-left: 3.2rem;
  margin-bottom: 0;
  width: calc(100% - 3.2rem);
  box-sizing: border-box;
  box-shadow: none;
}

.home__vote {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  padding: 0 1.8rem 4.2rem;
  justify-content: center;
  align-items: flex-start;
  gap: 4.2rem;
}

.home__vote.home__vote--bold {
  --section-color: var(--cta);
  padding: 4.8rem 0;
  margin: 0px auto 0px;
}

.home__vote--bold * {
  color: white !important
}

.home__vote--bold .button, .home__vote--bold .quill-button {
  background-color: var(--primary);
}

.home__vote--bold .home__vote-image {
  transform: rotate(-3deg);
  border: 1.8rem solid var(--cta-0);
  border-radius: 0px;
  box-shadow: var(--elevation-6), var(--elevation-6), var(--elevation-6);
}

.home__vote-text {
  width: 100%;
  align-self: flex-start;
}

.home__vote-image {
  margin-left: 0.8rem;
  width: 45%;
  max-height: calc((var(--site-width) / 2) - 10.4rem);
  max-height: calc((var(--site-width) / 2) - 10.4rem);
  height: auto;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 0.4rem;
  box-shadow: -0.8rem 0.8rem 0 0 var(--cta);
}

.home__vote-image + .home__vote-text {
  width: 50%;
}

.home__vote-cta {
  margin: 2.4rem auto 0;
}


@media (max-width: 850px) {
  .home__about-text {
    margin-bottom: 2.4rem;
    width: 100%;
  }
  .home__about-image {
    box-shadow: -0.8rem 0.8rem 0 0 var(--cta);
    width: 100%;
    margin: 0.8rem 0.8rem 2.4rem 0.4rem;
    height: auto;
  }
  .home__about--bold .home__about-image, 
  .home__vote--bold .home__vote-image {
    margin-top: -2rem;
  }
  .section.home__about, .home__endorsements, .home__issues, .contribute-cta, .home__volunteer {
    padding: 1.8rem 1.2rem 2.8rem;
    margin: 0;
  }
  .home__volunteer {
    flex-flow: column-reverse;
  }
  .home__volunteer::before {
    background: linear-gradient(180deg, var(--primary), transparent);
  }
  .section.home__about {
    flex-flow: column;
  }
  .home__endorsements {
    padding-bottom: 5.2rem;
  }
  .home__volunteer-image {
    display: block;
    position: absolute;
    width: 100%;
    z-index: -2;
    mix-blend-mode: color-burn;
    opacity: 0.33;
    filter: brightness(0.75)
  }
  .home__volunteer .volunteer__form {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .home__vote, .home__vote--bold {
    padding: 2.4rem 1.2rem 2.4rem !important;
    flex-flow: column;
    gap: 2.6rem;
  }
  .home__vote-image {
    width: 100%;
    max-height: unset;
    margin-left: 0.4rem;
  }
  .home__vote-text {
    width: 100% !important;
  }
}