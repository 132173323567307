.store__iframe {
  width: 100%;
  height: 100vh;
  border: 0;
  transition: height .18s ease-in-out;
}

.store__footer {
  display: flex;
  background: var(--primary-8);
  color: var(--primary-1);
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 2.4rem;
  font-size: 1.8rem;
  font-weight: bold;
  box-shadow: -50vw 0 0 0 var(--primary-8), 50vw 0 0 0 var(--primary-8), -50vw 100px 0 0 var(--primary-8), 50vw 100px 0 0 var(--primary-8);
  margin-bottom: -14.8rem;
  padding-bottom: 16.8rem;
  z-index: 0;
  position: relative;
}

.store__goodstock-logo {
  transform: translateX(2%);
  height: 7.2rem;
  width: 100%;
  margin: -0.8rem 0 0;
  color: white;
}

.store__upsell {
  height: calc(100vh - 9.8rem);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  background: var(--primary-8);
  position: relative;
  padding-top: calc((100vh - 9.8rem) / 10);
  text-shadow: 0 0 2.4rem var(--primary-8);
}

.store__upsell::before  {
  content: "";
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 5.8rem 2.8rem var(--primary-8);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.store__upsell::after  {
  content: "";
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background-color: var(--primary-8);
  position: absolute;
  z-index: -2;
  top: 0;
}

.store__upsell-banner {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 15%;
  mix-blend-mode: overlay;
  filter: blur(3px);
  z-index: 0;
}

.store__upsell-button {
  background: var(--cta-5);
  color: white !important;
  border-radius: 2.4rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 4.8rem;
  position: relative;
  z-index: 2;
  text-decoration: none !important;
}

.store__upsell-title {
  color: white;
  font-size: 3.8rem;
  font-family: var(--serif);
  position: relative;
  z-index: 2;
  letter-spacing: 1px;
}

.store__upsell-subtitle {
  color: white;
  font-size: 2.4rem;
  font-family: var(--serif);
  position: relative;
  z-index: 2;
  letter-spacing: 1px;
  max-width: 52rem;
  margin-bottom: 2.8rem;
}

.store__upsell-logo .store__goodstock-logo {
  height: 12.8rem;
  position: relative;
  z-index: 2;
  margin-bottom: 4.2rem;
  margin-top: -1.8rem;
  filter: drop-shadow(0 0 2.4rem var(--primary-8));
}

.store__upsell-text {
  color: white;
  font-size: 2rem;
  margin: 0 0 1.8rem;
  max-width: 52rem;
  position: relative;
  z-index: 2;
  line-height: 1.2;
}

.store__upsell-text em {
  text-decoration: underline;
  font-variant: normal;
  font-style: normal;
  font-weight: bold;
}

.store__upsell-url {
  font-family: var(--serif);
  font-size: 2.4rem;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-weight: bold;
  margin-bottom: 6.2rem;
}

.store__upsell-subdomain {
  display: inline-block;
  visibility: visible;
  position: relative;
  z-index: 2;
  background: white;
  color: var(--cta-5);
  font-weight: bold;
  text-shadow: none;
  border: 2px solid var(--cta-5);
  border-radius: 0.8rem;
  padding: 0.8rem 1.4rem;
  margin: 0 0.4rem 0 0;
}