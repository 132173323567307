.section.header {
  --section-color: var(--primary);
  align-items: center;
  color: var(--primary-text);
  display: flex;
  flex-flow: row-reverse;
  justify-content: center;
  /* justify-content: space-between; */
  padding: 4.2rem;
  background-color: transparent;
}

.header__content {
  font-size: var(--font-3);
  max-width: 48ch;
  text-align: center;
  width: 100%;
  width: 55.6rem;
}

.header__content h2:first-of-type {
  font-size: var(--font-5);
  font-weight: 400 !important;
  line-height: 1;
}

.header__image {
  background-size: 0.6rem 0.6rem;
  background: var(--primary-0);
  box-shadow: -0.8rem 0.8rem 0 0 var(--cta);
  height: auto;
  margin-left: 3.2rem;
  max-width: calc(50% - 3.2rem);
  object-fit: contain;
  z-index: 1;
}

.header--hero {
  min-height: 48rem;
  justify-content: flex-start;
}
.header--hero .header__image {
  height: 100%;
  left: 50%;
  margin: 0;
  max-width: unset;
  object-fit: cover;
  position: absolute;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 0;
}
.header--hero .header__content {
  align-items: flex-start;
  background: white;
  color: var(--gray-10);
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 6.4rem 0;
  min-height: 12rem;
  padding: 3.2rem;
  z-index: 1;
}

.header__image + .header__content {
  text-align: right;
}

.header--hero .header__content {
  text-align: left;
}

.header--hero .header__content:empty {
  display: none;
}

.header--hero .header__content h1 {
  font-size: 4.2rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 0;
}

.header--hero .header__content h2 {
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 1.2;
}

@media (max-width: 850px) {
  .section.header {
    flex-flow: column;
    height: auto;
    min-height: 75vh;
    min-height: 90px;
  }

  .header__image {
    display: none;
    height: 50vw;
    margin: -2.4rem -2.4rem 2.4rem;
    width: 100vw;
  }

  .header__content {
    text-align: center !important;
    width: 100%;
  }
}