/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.volunteer__body {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: 50% 50%;
  max-width: var(--site-width);
  margin: auto;
  min-height: calc(100vh - 12.8rem);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1.8rem;
  z-index: 3;
  transform: translate3d(0, 0, 0); /* needed for ios rendering bug */
  --section-color: var(--secondary);
}

.volunteer__body .volunteer__form fieldset label,
.volunteer__body .volunteer__checkboxes label::before {
  color: black;
  border-color: black;
}

fieldset.wide.volunteer__checkboxes {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc(50% - 0.9rem) 1.8rem calc(50% - 0.9rem);
  grid-template-columns: calc(50% - 0.9rem) calc(50% - 0.9rem);
  grid-gap: 0.8rem 1.8rem;
}

.volunteer__checkboxes h2 {
  font-size: 1.8rem !important;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  text-align: left;;
}

.volunteer__checkboxes input {
  display: none;
}

.volunteer__checkboxes label {
  font-size: 1.6rem !important;
  padding-left: 3.2rem;
  position: relative;
  opacity: 1 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 3.2rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.volunteer__checkboxes label::before {
  content: "";
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  border: 2px solid currentColor;
  border-radius: 2px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: inherit;
  -webkit-transition: background-color .08s ease-in-out, color .08s ease-in-out;
  -o-transition: background-color .08s ease-in-out, color .08s ease-in-out;
  transition: background-color .08s ease-in-out, color .08s ease-in-out;
}

.volunteer__checkboxes input:checked + label::before {
  content: "✔";
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  border: 2px solid currentColor;
  border-radius: 2px;
  left: 0;
  background: transparent;
}

.volunteer__bg {
  position: fixed;
  top: 0rem;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100vw;
  height: 100vh;
  -o-object-position: top center;
     object-position: top center;
}

main::before {
  position: absolute;
  max-width: 100vw;
  left: 0;
  right: 0;
}

.volunteer__body::before {
  content: "";
  border: calc(25vw + 6.4rem + 4.3rem) solid transparent;
  position: absolute;
  top: 48rem;
  right: calc(50% - 50vw);
  border-top-color: var(--primary);
  border-right-color: var(--primary);
  z-index: -1;
  opacity: 0.033;
  display: none;
}

.volunteer__form {
  padding: 1.8rem;
  background: white;
  -webkit-box-shadow: var(--elevation-4);
          box-shadow: var(--elevation-4);
  border-radius: 0.4rem;
  display: -ms-grid;
  display: grid;
  grid-gap: 0.8rem;
  border: 1px solid rgba(0,0,0,.15);
  background-clip: padding-box;
  -ms-grid-columns: calc(50% - 0.9rem) 1.8rem calc(50% - 0.9rem);
  grid-template-columns: calc(50% - 0.9rem) calc(50% - 0.9rem);
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.volunteer__form .volunteer__form-feedback:empty {
  display: none !important;
}

.volunteer__form .volunteer__form-feedback {
  background: rgba(0,0,0, .15);
  color: var(--black);
  font-weight: bold;
  display: block;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  margin: -1.8rem 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 4.8rem;
}

.volunteer__form--success .volunteer__form-feedback {
  background: var(--green);
  color: white;
}

.volunteer__form--error .volunteer__form-feedback {
  background: var(--red);
  color: white;
}

.form__title {
  font-size: 3.2rem !important;
}

.volunteer__form input[type=email], .volunteer__form input[type=tel], .volunteer__form textarea {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  resize: none;
}

.volunteer__form fieldset {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
}

.volunteer__form fieldset.wide {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
}

.volunteer__form fieldset label {
  color: var(--primary-text);
  font-weight: bold;
  font-size: var(--font-1);
  margin-bottom: 4px;
  height: 0;
  opacity: 0;
  margin: 0;
  text-align: left;
  line-height: 1.1;
}

.volunteer__form textarea {
  height: 5.8rem;
  line-height: 1.4;
}

.volunteer__form input[type=submit] {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  padding: 1.2rem 2.4rem;
  border: none;
  color: white;
  background: var(--cta-5);
  font-weight: bold;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  width: fit-content;
}

.volunteer__disclaimer {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 1.4rem;
  opacity: .75;
}

.volunteer__disclaimer p {
  font-size: 1.6rem;
}

.home__volunteer .volunteer__form input[type=submit] {
  background: white;
  color: var(--primary);
}

@media (max-width: 850px) {
  .volunteer__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    min-height: calc(100vh - 8.2rem);
    padding: 8.2rem 0.8rem 1.8rem;
  }
  .volunteer__bg {
    height: 100vh;
    -o-object-position: top right;
       object-position: top right;
    right: -50vh;
  }
}
