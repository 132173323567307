.issues {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, calc(33% - ((0.8rem * 2) / 3)));
  grid-gap: 0.8rem;
  text-align: left;
  width: auto;
}

.issue {
  list-style: none;
  background: white;
  border: 4px solid var(--primary-8);
  padding: 1.8rem 1.8rem 2.6rem;
  color: var(--primary-8);
  position: relative;
  display: flex;
  flex-flow: column;
}

.issue::after {
  content: "";
  width: 100%;
  height: 0.8rem;
  background: var(--cta);
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
}

.issue__name {
  margin-bottom: 0.4rem;
  font-size: var(--font-3);
}

.issue__image {
  width: 6.2rem;
  height: 6.2rem;
  object-fit: contain;
  margin-bottom: 1.2rem;
}

.issue__description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  line-height: 1.2;
  font-size: var(--font-2);
}

.issue__description-long {
  flex-grow: 1;
}

.issue__description * {
  font-size: var(--font-3);
}

.issue__description *:not(:first-child) {
  display: none;
  overflow: hidden;
}

.issue__description *:first-child {
  overflow: hidden;
}

.issue__description *:last-child {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .issues {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 850px) {
  .issues {
    margin: 3.2rem 0;
    display: flex;
    flex-flow: column;
  }
  .issue {
    margin-bottom: 0rem;
  }
}