@import "./wysiwyg.css";
@import "./lists.css";
@import "./navigation.css";
@import "./button.css";

@import "./components/footer.css";
@import "./components/section.css";
@import "./components/SocialIcons.css";
@import "./components/emailForm.css";
@import "./components/endorsements.css";
@import "./components/issues.css";
@import "./components/ContributeCta.css";
@import "./components/VolunteerForm.css";
@import "./components/Header.css";
@import "./components/HeroBanner.css";

@import "./pages/index.css";
@import "./pages/page.css";
@import "./pages/petitions.css";
@import "./pages/endorsements.css";
@import "./pages/donate.css";
@import "./pages/blog.css";
@import "./pages/issues.css";
@import "./pages/volunteer.css";
@import "./pages/store.css";
@import "./pages/gallery.css";

@import "./preview.css";

@font-face {
  font-family: "fontawesome";
  src: url(https://cdn.universe.app/aether/1.0.0/fonts/fontawesome/fa-solid-900-all.woff2);
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
}

html {
  /* https://matthewjamestaylor.com/responsive-font-size */
  /* 10px at 1280px width */
  font-size: calc((15px + 0.390625vw) * 0.5);
  font-size: max(calc((15px + 0.390625vw) * 0.5), 9px);
  font-family: var(--sans);
  box-sizing: border-box;
  scroll-behavior: smooth;
  --site-width: 1280px;

  /* https://type-scale.com */
  /* "Major-Third" with 20px base */
  --font-0: 1.024rem;
  --font-1: 1.280rem;
  --font-2: 1.600rem;
  --font-3: 2.000rem;
  --font-4: 2.500rem;
  --font-5: 3.125rem;
  --font-6: 3.906rem;
  --font-7: 4.883rem;
  --font-8: 6.104rem;
}

body {
  --matte: #F5F9FC;
  --light-gray: #E1E9F0;

  --red: #FF564A;
  --green: #66E58C;
  --light-red: #FCEBEB;
  --light-green: #E8FAEA;

  --light-blue: #a6b6c4;
  --blue: #2E71B2;
  --dark-blue: #132f53;
  --light-red: #e995a7;
  --red: #E4264F;
  --dark-red: #420b17;
  --green: #059456;
  --light-yellow: #e9c195;
  --yellow: #FAA040;
  --light-gray: rgba(0,0,0,.1);
  --gray: rgba(0,0,0,.55);
  --purple: rgb(55,58,94);
  --dark-purple: rgb(35, 37, 63);

  --elevation-0: 0 0 0 1px rgba(0, 0, 0, 0.01);
  --elevation-2: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 2px 3px rgba(0, 0, 0, 0.1);
  --elevation-4: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 4px 6px rgba(0, 0, 0, 0.1);
  --elevation-6: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 6px 9px rgba(0, 0, 0, 0.1);
  --elevation-12: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 12px 18px 1px rgba(0, 0, 0, 0.1);


  font-size: var(--font-3);
  color: var(--gray-10);
  margin: 0 1.8rem;
  background-color: var(--matte);
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--primary);
  font-weight: 800;
  text-decoration: none;
  transition: box-shadow .18s ease-in-out;
  cursor: pointer;
}

a:hover,
a:active {
  text-decoration: underline;
}

a:visited {
  color: var(--primary);
}

.wysiwyg a:not(.button):not(.quill-button):hover, 
.wysiwyg a:not(.button):not(.quill-button):focus {
  box-shadow: inset 0 -2px 0 0 currentColor;
  font-weight: bold;
  text-decoration: none;
}

p {
  font-size: var(--font-2);
  line-height: 1.2;
  margin: 0 0 1.2em;
}

img[src^="data"] {
  background-color: var(--matte);
  background-image: linear-gradient(-45deg, transparent calc(50% - 1px), rgb(218, 227, 235) 50%, transparent calc(50% + 1px));
  background-repeat: repeat;
  background-size: 0.6rem 0.6rem;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
}

form .field {
  margin-bottom: 2rem;
}

form input, form textarea {
  font-family: inherit;
  font-size: inherit;
  border: 1px solid var(--gray);
  border-radius: 2px;
}

form input:not([type=submit]), form textarea {
  width: 100%;
  padding: 1rem;
  color: black;
  border: 2px solid var(--primary-9);
  border-radius: 0.2rem;
  outline: 0;
}

form textarea {
  height: 12rem;
}

select {
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  width: calc(50% - 1.2rem);
  border: 1px solid var(--gray);
  padding: 1.2rem 3.2rem 1.2rem 1.2rem;
  line-height: 1;
  background: white;
  border-radius: 2px;
  float: left;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23909eb2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-position: center right 1rem;
  background-size: 1.2rem;
  background-repeat: no-repeat;
  color: black;
  margin-bottom: 1.2rem;
}

@media (max-width: 850px) {
  html, body {
    height: 100%;
    width: 100vw;
    margin: 0;
    overflow: hidden;
    background: var(--primary-9);
    -webkit-overflow-scrolling: auto;
    --site-width: 100vw;
  }
}
