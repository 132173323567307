.endorsements {
  list-style: none;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 2.4rem;
  margin-bottom: 4.8rem;
}

.endorsements:empty {
  display: none;
}

.endorsements:empty + #for-id-only-desc,
.endorsements:not(:has(.endorsement)) + #for-id-only-desc,
.endorsements.endorsements--hidden + #for-id-only-desc {
  display: none;
}

.endorsement {
  text-align: center;
}

.endorsement.endorsement--no-image {
  text-align: left;
}

.endorsement__image {
  max-width: 18rem;
  max-height: 18rem;
  padding: 0.1rem;
  border-radius: 4px;
  margin: 0;
  object-fit: contain;
  box-shadow: none;
  position: relative;
  z-index: 1;
}

.endorsement__image-container {
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba(0,0,0,.15), -0.4rem 0.4rem 0 0 var(--cta);
  margin-bottom: 1.2rem;
  border-radius: 4px;
  display: flex;
  height: 18rem;
  justify-content: center;
  align-items: center;
}

.endorsement__image-background {
  width: calc(100% + 7.2rem);
  height: calc(100% + 7.2rem);
  display: block;
  top: -3.6rem;
  left: -3.6rem;
  margin-bottom: 0.4rem;
  object-fit: cover;
  object-position: center;
  z-index: 0;
  position: absolute;
  filter: blur(2.8rem) saturate(1.5);
  opacity: 0.5;
}

.endorsement__quote {
  max-width: 28rem;
  margin: 0.8rem 0 0;
  word-break: break-word;
  font-family: var(--serif);
  position: relative;
  font-size: var(--font-2);
  max-width: 28rem;
}

.endorsement__quote:before {
  color: var(--primary-1);
  content: "\f10d";
  font-family: "fontawesome";
  font-size: var(--font-5);
  left: -0.8rem;
  position: absolute;
  top: -1.4rem;
  z-index: -1;
  opacity: 1;
  line-height: 1;
}

#for-id-only-desc {
  display: block;
  text-align: right;
  margin: 2.4rem 0;
}

.endorsement.endorsement--id-only .endorsement__title::after, #for-id-only-desc::before {
  content: " * ";
  color: var(--red);
  font-weight: bold;
}

@media (max-width: 850px) {
  .endorsements {
    margin: 3.2rem 0;
    display: flex;
    flex-flow: column;
  }
  .endorsement {
    margin-bottom: 2.4rem;
  }
  #for-id-only-desc {
    text-align: center;
  }
}