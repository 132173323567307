#card-element {
  background: white;
  padding: 12px;
  width: 100%;
  border: 1px solid;
  margin-bottom: 1.2rem;
}

.donate__container {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: stretch;
}

.donate__left {
  margin: 0;
  padding: 1.8rem;
  width: 50%;
  max-width: calc(var(--site-width) / 2);
  height: fit-content;
  position: sticky;
  top: 0;
  border: none;
  background: transparent;
}

.donate__ask {
  width: 100%;
  max-width: 580px;
  margin-right: 4.8rem;
}

.donate__form {
  z-index: 2;
  position: relative;
  background-color: var(--matte);
  box-shadow: calc((-1 * (min(100vw, var(--site-width)) / 2)) + 1.9rem) 0 0 0 var(--matte);
  min-height: calc(100vh - 10.2rem);
  display: flex;
  flex-flow: row-reverse;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
}

.donate__form::before {
  content: "";
  background-color: rgba(var(--gray-0-rgb), 0.9);
  backdrop-filter: blur(8px);
  width: 50%;
  max-width: calc(var(--site-width) / 2);
  position: absolute;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-100%);
}

.donate__form-title {
  text-align: left;
  width: 52rem;
  margin-bottom: 0.8rem;
}

.donate__embed {
  width: 100%;
  max-width: calc(var(--site-width) / 2);
  height: calc(100vh - 10.2rem);
  border: 0;
  position: sticky;
  top: 0px;
  overflow: hidden;
  background-color: transparent;
  z-index: 2;
  padding: 0;
  will-change: height;
}

.donate__content {
  width: 50%;
  max-width: calc(var(--site-width) / 2);
  margin: 0;
  float: right;
  z-index: 1;
  min-height: calc(100vh - 10.2rem);
  box-sizing: border-box;
  padding: 0;
  background: transparent;
}

.donate__content::after {
  display: none;
}

.donate__banner {
  position: absolute;
  top: 0;
  left: 0%;
  width: 50vw;
  height: calc((100vh - 10.2rem) / 2);
  height: 100%;
  z-index: -1;
  object-fit: contain;
}

.donate__logo {
  width: 100%;
  height: calc((100vh - 10.2rem) / 2);
  object-fit: contain;
}

.donate__body {
  padding: 1.8rem;
  box-sizing: border-box;
  background: var(--matte);
}

.donate__content--text {

}

.donate__content--text .donate__banner {
  right: 50%;
  width: 50%;
  position: relative;
  object-fit: cover;
}

.donate__content--text .donate__logo {
  left: 50%;
  width: 50%;
  position: absolute;
  object-fit: cover;
}

.donate__content--text:not(.donate__content--img) .donate__body {
  margin-top: 0;
  min-height: calc(100vh - 10.2rem);
}

.donate__banner.donate__banner--wide {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  object-fit: cover;
  z-index: -2;
  transform: translateX(-50vw);
}

.donate__banner--wide + .donate__logo, 
.donate__logo:first-of-type:last-of-type,
.donate__banner:not(.donate__banner--wide):first-of-type:last-of-type {
  position: relative;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  right: unset;
  object-fit: contain;
}

.donate__handle {
  display: none;
}

.donate__inline-disclaimer {
  position: absolute;
  bottom: 0px;
  pointer-events: none;
  opacity: 0;
  width: calc(100% - 3.6rem);
}

.donate__inline-disclaimer > span[data-neutrino] *:last-child {
  padding-bottom: 28rem;
}

@media (max-width: 850px) {
  .main {
    scroll-snap-type: y mandatory;
    scroll-padding-top: 8.2rem;
    scroll-behavior: smooth;
  }
  .donate__container {
    flex-flow: column;
  }
  .donate__ask {
    margin: 0 0 2.4rem;
  }
  .donate__form {
    --mobile-padding: 1.8rem;
    --mobile-card-peek-height: 15.8rem;
    margin-top: 0;
    flex-flow: column-reverse;
    align-items: center;
    justify-content: flex-end;
    background: white;
    box-shadow: none;
    padding: calc(var(--mobile-padding) + 0.4rem) var(--mobile-padding);
    width: 100%;
    z-index: 3;
    display: contents;
  }

  .donate__content {
    width: 100%;
    max-width: unset;
    height: calc(100% - var(--mobile-card-peek-height));
    display: block;
    position: sticky;
    top: 0;
    padding: 8.2rem 1.8rem 1.8rem;
    overflow: auto;
    min-height: unset;
    scroll-behavior: smooth;
    bottom: var(--mobile-card-peek-height);
    margin-bottom: -100vh;
    box-shadow: 0 1.8rem 0 0 var(--matte), inset 0 -28rem 0 0 var(--matte);
  }

  .donate__body {
    min-height: unset !important;
    padding: 0 0 1.8rem;
    box-shadow: 0 0 0 1.8rem var(--matte);
  }
  .donate__embed {
    height: 50vh;
    width: 100%;
    position: relative;
    max-width: unset;
  }
  .donate__form::before {
    content: "";
    display: block;
    scroll-snap-align: start;
    height: calc(100% - var(--mobile-card-peek-height));
    position: relative;
    width: 100vw;
    max-width: unset;
    left: 0;
    transform: none;
    pointer-events: none;
    opacity: 0;
    margin-bottom: -2.4rem;
  }
  .donate__left {
    width: 100%;
    max-width: unset;
    padding: 0;
    position: sticky;
    left: 0;
    z-index: 99;
    background: transparent;
    padding: 1.8rem;
    top: unset;
    transition: height .18s ease-in-out, border-width .18s ease-in-out;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    height: calc(100% - 8.2rem);
    overflow: hidden;
    background: white;
    background-clip: content-box;
    background: white;
    border-radius: 3.2rem 3.2rem 0 0 / 2.4rem;
    box-shadow:
      -1px -1px 0px 0 var(--primary-5),
      1px -1px 0px 0 var(--primary-5),
      -2px -4px 0 0 var(--primary-4),
      2px -4px 0 0 var(--primary-4),
      0 -4px 0 1px rgba(0, 0, 0, 0.01),
      0 -6px 3px rgba(0, 0, 0, 0.1);
  }
  .donate__handle {
    width: 100%;
    height: 4.8rem;
    display: block;
    background: transparent;
    color: transparent;
    border: none;
    position: relative;
    margin: -2.4rem 0;
    z-index: 3;
  }
  .donate__handle::before {
    content: "";
    width: 18rem;
    height: 0.6rem;
    background: rgba(0, 0, 0, .25);
    position: relative;
    top: 8px;
    border-radius: 0.8rem;
    left: 50%;
    transform: translateX(-50%);
    color: transparent;
    font-size: 0;
    border: none;
    display: block;
    margin: -2.4rem 0;
    z-index: 1;
  }
  .donate__form ~ .footer {
    padding-top: 2.4rem;
    scroll-snap-align: end;
    scroll-snap-stop: always;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01), 0 -2px 3px rgba(0, 0, 0, 0.1);
  }
  .donate__form ~ .home__ctas,
  .donate__form ~ .footer .footer__links,
  .donate__form ~ .footer .email-form
   {
    display: none;
  }

  .donate__banner--wide + .donate__logo, 
  .donate__logo:first-of-type:last-of-type,
  .donate__banner:not(.donate__banner--wide):first-of-type:last-of-type {
    width: calc(100% + 3.6rem);
    margin: -8.2rem -1.8rem 1.8rem;
  }

  .donate__banner.donate__banner--wide {
    display: none;
  }
}
