.gallery__thumbs {
  --row-count: 5;
  --row-width: calc(min(var(--site-width), 100vw) - 4.2rem);
  --width: calc(var(--row-width) / var(--row-count));
  --hover-width: calc(var(--width) * 1.5);
  --small-width: calc((var(--row-width) - var(--hover-width)) / (var(--row-count) - 1));
  max-width: var(--row-width);
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-flow: row;
  flex-wrap: wrap;
  list-style: none;
  width: min(calc(var(--count, 0) * var(--width)), var(--row-width));
}

@media (max-width: 1180px) {
  .gallery__thumbs {
    --row-count: 4;
  }
}


@media (max-width: 1024px) {
  .gallery__thumbs {
    --row-count: 4;
  }
}

@media (max-width: 920px) {
  .gallery__thumbs {
    --row-count: 3;
  }
}

@media (max-width: 680px) {
  .gallery__thumbs {
    --row-count: 2;
  }

  .gallery__thumb:hover,
  .gallery__thumb:target {
    flex-grow: 1 !important;
    max-width: var(--width) !important;
  }

  .gallery__thumb:target .gallery__thumb-image {
    transform: translate(-50%,-50%) translateX(calc(100vw - 4.8rem)) !important;
  }

  .gallery__lightbox-close,
  .gallery__lightbox-button {
    transform: translateX(calc(100vw - 4.8rem));
  }
}

.gallery__thumbs hr {
  flex-basis: calc(100% - min(100%, 100% * mod(var(--idx), var(--row-count))));
  flex-shrink: 1;
  height: 0;
  margin: 0;
  border: 0;
  background: transparent;
  padding: 0;
}

.gallery__thumb {
  max-width: var(--width);
  flex-basis: 1px;
  flex-grow: 1;
  height: var(--width);
  position: relative;
  transition: flex-grow .28s ease-in-out, max-width .28s ease-in-out;
  overflow: hidden;
  flex-shrink: 1;
  scroll-behavior: smooth;
}

.gallery__thumb:hover,
.gallery__thumb:target {
  flex-grow: 1.8;
  max-width: var(--hover-width);
}

.gallery__thumb-bg {
  position: absolute;
  top: -1.8rem;
  left: -1.8rem;
  width: calc(100% + 2.6rem);
  height: calc(100% + 2.6rem);
  filter: blur(8rem) brightness(1.8);
  z-index: -1;
  object-fit: cover !important;
  opacity: 0.55
}

.gallery__thumb:target .gallery__thumb-image {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  z-index: 999;
  border: 5.8rem solid transparent;
  background: rgba(0,0,0,.33);
  pointer-events: none;
  object-fit: contain !important;
}

.gallery__download {
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  opacity: 0;
  transition: opacity .28s ease-in-out;
  z-index: 10;
}

.gallery__thumb:hover .gallery__download {
  opacity: 1;
}

.gallery__thumb-link { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery__thumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.gallery__lightbox-button {
  z-index: 9999;
  position: fixed;
  top: 0%;
  pointer-events: none;
  width: 50vw;
  height: 100vh;
  opacity: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.8rem;
  color: white !important;
  font-size: 0px;
  text-decoration: none !important;
  transition: opacity .18s ease-in-out;
}

.gallery__lightbox-button--prev {
  left: 0rem;
  text-align: left;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgba(0,0,0,.25) 0px, transparent 8.2rem);
}

.gallery__lightbox-button--prev::before {
  content: "\f053";
  font-family: 'fontawesome';
  font-size: 4.2rem;
}

.gallery__lightbox-button--next {
  right: 0rem;
  text-align: right;
  justify-content: flex-end;
  background-image: linear-gradient(-90deg, rgba(0,0,0,.25) 0px, transparent 8.2rem);
}

.gallery__lightbox-button--next::before {
  content: "\f054";
  font-family: 'fontawesome';
  font-size: 4.2rem;
}

.gallery__thumb:target .gallery__lightbox-button {
  pointer-events: all;
  opacity: 0.55;
}

.gallery__thumb:target .gallery__lightbox-button:hover {
  pointer-events: all;
  opacity: 1;
}

.gallery__lightbox-close {
  position: fixed;
  top: 0rem;
  right: 0rem;
  min-width: 4.8rem;
  height: 4.8rem;
  color: white !important;
  opacity: 0;
  z-index: 99999;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.8rem;
  box-sizing: content-box;
  text-decoration: none !important;
  gap: 0.8rem;
  text-shadow: 0 0 30px black, 0 0 60px black;
}

.gallery__lightbox-close::after {
  content: "\f00d";
  font-size: 2.8rem;
  font-family: 'fontawesome';
}

.gallery__thumb:target ~ li .gallery__lightbox-close {
  opacity: 0.66;
  pointer-events: all;
}

.gallery__thumb:target ~ li .gallery__lightbox-close:hover {
  opacity: 1;
}
