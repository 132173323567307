.issues__body .issues {
  list-style: none;
  display: grid;
  grid-template-columns: calc(50% - 1.2rem) calc(50% - 1.2rem);
  grid-gap: 2.4rem;
  text-align: left;
  padding: 0 1.8rem 2.8rem;
}

.issue .button {
  margin-top: 1.8rem;
  float: right;
  width: 100%;
}

@media (max-width: 850px) {
  .issues__body .issues {
    list-style: none;
    display: grid;
    grid-template-columns: 100%;
    padding: 0 0 2.8rem;
  }
  .issues__body {
    padding: 1.8rem;
  }
  .issues__body .issue {
    margin-bottom: 0;
  }
}
