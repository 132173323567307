.endorsements__body {
  display: flex;
  flex-flow: row;
  min-height: 18.2rem;
  justify-content: space-between;
  gap: 0 2.4rem;
}

.endorsements__section-title {
  width: 100%;
  font-size: 0rem;
  color: transparent;
  margin: 0;
  height: 0;
  margin: 0;
}

.endorsements--hidden {
  display: none !important;
}

.endorsements:empty, .endorsements:not(:has(.endorsement)), .endorsements--hidden {
  display: none !important;
}

.endorsements::before {
  content: attr(data-title);
  grid-column: 1 / -1;
  text-align: left;
  font-size: var(--font-5);
  font-weight: 800;
  margin-bottom: 1.8rem;
  line-height: 1;
}

.endorsements__photos {
  flex-grow: 2;
  width: 100%;
}

.endorsements__no-photos {
  flex-grow: 1;
  max-width: 100%;
  min-width: 28rem;
  margin: 0;
}

.endorsements__no-photos .endorsements {
  grid-template-columns: auto auto auto;
  gap: 2.4rem;
}

.endorsements__photos + .endorsements__no-photos .endorsements {
  grid-template-columns: 100%;
}

.endorsements {
  grid-column: 1;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 18rem);
  grid-gap: 1.2rem;
  margin-bottom: 4.8rem;
}

.endorsements:empty {
  display: none !important;
}

.endorsement {
  text-align: left;
}

.endorsement__name {
  font-size: var(--font-4);
  text-transform: none;
  color: var(--primary-8);
  line-height: 1;
  margin-bottom: 0.2rem;
}

.endorsement__title {
  font-size: var(--font-2);
  font-family: var(--sans);
  text-transform: uppercase;
  color: var(--primary-6);
}

.endorsement.endorsement--no-image {
  text-align: left;
}

#for-id-only-desc {
  display: block;
  text-align: right;
  margin: 2.4rem 0 0;
  position: absolute;
  right: 0;
  font-size: 1.8rem;
  bottom: 1.8rem;
  right: 0.8rem;
}

.home__endorsements-cta {
  margin: 0 auto;
  background: var(--primary-5);
  color: var(--primary-text);
  width: 100%;
  max-width: 32rem;
}

.endorsement.endorsement--id-only .endorsement__title::after, #for-id-only-desc::before {
  content: " * ";
  color: var(--red);
  font-weight: bold;
}

.endorsement.endorsement--id-only .endorsement__title::after {
  margin-right: -16px;
  white-space: pre;
}

.endorsements__photos:empty {
  display: none !important;
}

@media (max-width: 1024px) {
  .endorsements__body .endorsements__photos .endorsements {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 18rem);
    grid-gap: 1.2rem;
    margin-bottom: 4.8rem;
  }
  .endorsements__no-photos .endorsements {
    grid-template-columns: auto auto;
    gap: 1.8rem;
  }
}

@media (max-width: 792px) {
  .endorsements__body .endorsements__photos .endorsements {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 18rem);
    grid-gap: 1.2rem;
    margin-bottom: 4.8rem;
  }
}

@media (max-width: 680px) {
  .endorsements__no-photos .endorsements {
    grid-template-columns: auto;
    gap: 1.8rem;
  }
  .endorsements {
    margin: 3.2rem auto;
    grid-column: 1;
    width: fit-content;
  }

  .endorsements__section-title {
    font-size: 2.4rem;
  }

  .endorsements__body .endorsements__photos .endorsements {
    margin-bottom: 0;
  }

  .endorsement__quote {
    margin: 1.2rem auto 0;
    width: fit-content
  }

  .endorsements::before {
    text-align: center;
    margin-bottom: 2.8rem;
    font-size: var(--font-6);
  }
  .endorsements__body {
    display: flex;
    flex-flow: column;
  }
  .endorsement {
    margin-bottom: 2.4rem;
    text-align: center !important;
  }
  .endorsements__section-title {
    text-align: center !important;
    width: 100%;
    display: block;
  }
  #for-id-only-desc {
    text-align: center;
  }
  .endorsements__no-photos {
    margin: 0 auto;
  }
}

