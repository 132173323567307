.section.home__header {
  width: 100%;
  max-width: var(--site-width);
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  background-color: transparent;
  min-height: min(50vw, calc(var(--site-width) / 2));
  margin-bottom: 14.2rem;
  padding-bottom: 0;
  display: grid;
  grid-template-columns: 50% 50%;
  --header-background: var(--primary-5);
  --header-accent: var(--primary-9);
  --header-text: var(--primary-5-text);
}

.section.home__header--cta {
  --header-background: var(--cta-5);
  --header-accent: var(--cta-9);
  --header-text: var(--cta-5-text);
}

.home__header::before {
  content: "";
  width: 100vw;
  position: absolute;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50vw);
  background: linear-gradient(to right, var(--header-background), var(--header-background) calc(100% - 75vw), transparent calc(100% - 25vw), transparent 100%);
}

.home__header-content {
  text-align: left;
  display: flex;
  flex-flow: column-reverse;
  width: 66vw;
  max-width: 72rem;
  justify-content: center;
  padding: 5.8rem 0;
}

.home__header-image {
  width: 75vw;
  height: 100%;
  position: absolute;
  right: 50%;
  top: 0;
  transform: translateX(50vw);
  z-index: -2;
}

.header__title {
  font-size: var(--font-8);
  line-height: 1.2;
  font-weight: 800;
  color: var(--header-text);
  direction: ltr !important; /* Required to reset direction swap for reversed header content */
}

.header__subtitle {
  font-size: var(--font-5);
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5.2rem;
  color: var(--header-text);
  position: relative;
  font-family: var(--serif);
  direction: ltr !important; /* Required to reset direction swap for reversed header content */
}

.header__subtitle::after {
  content: "";
  width: 25%;
  height: 0.8rem;
  background: var(--header-accent);
  position: absolute;
  bottom: -2.8rem;
  left: 0;
}

.home__about .home__about-title {
  color: var(--cta);
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 3.2rem;
}

.home__ctas {
  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - ((0.9rem * 2) / 3)));
  grid-template-rows: 8.2rem;
  gap: 1.8rem 0.9rem;
  min-height: 20.8rem;
  direction: ltr; /* Required to reset direction swap for reversed header content */
  /* position: absolute;
  bottom: -14.2rem;
  left: 50%;
  transform: translateX(-50%); */
  background: var(--matte);
  padding: 1.8rem;
  border-radius: 0.8rem;
  width: 100%;
  grid-row: 2;
  grid-column-end: 3;
  grid-column-start: 1;
}

.home__ctas-list:empty {
  display: none !important;
}

.home__header .home__ctas {
  margin-bottom: -14.2rem;
}

.home__ctas .email-form {
  grid-column-start: 1;
  grid-column-end: 4;
}

.home__ctas .email-form__section {
  height: 5.2rem;
  width: 100%;
}

.home__ctas .home__ctas-button {
  width: 100%;
  height: 8.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-4);
  color: white;
  border-radius: 0.4rem;
}

.home__ctas .home__ctas-list, .home__ctas .home__ctas-list-item {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 4;
}

.home__ctas .home__ctas-list {
  gap: 1.8rem;
}

.home__ctas .home__ctas-button--one { background-color: var(--primary-9); color: var(--primary-9-text); }
.home__ctas .home__ctas-button--two { background-color: var(--primary-5); color: var(--primary-5-text); }
.home__ctas .home__ctas-button--three { background-color: var(--cta-5); color: var(--cta-5-text); }

.home__ctas--footer {
  position: relative;
  top: unset;
  left: unset;
  transform: none;
  margin: -0.8rem auto -5.2rem;
  bottom: unset;
  max-width: var(--site-width);
  z-index: 2;
}


/* Reverse Header Styles */
@media (min-width: 850px) {
  .home__header--rtl {
    direction: rtl;
  }

  .home__header--rtl .header__subtitle::after {
    left: unset;
    right: 0;
  }

  .home__header--rtl .home__header-image {
    left: 50%;
    right: unset;
    transform: translateX(-50vw);
  }

  .home__header--rtl .home__header-content {
    text-align: right;
  }

  .home__header--frame.home__header--rtl .home__header-content,
  .home__header--slant.home__header--rtl .home__header-content {
    text-align: left;
    padding-left: 1.8rem;
  }

  .home__header--frame.home__header--rtl .header__subtitle::after,
  .home__header--slant.home__header--rtl .header__subtitle::after {
    left: 0;
    right: unset;
  }

  .home__header--slant.home__header--rtl .header__title {
    left: -2.4rem;
    position: relative;
  }

  .home__header--slant.home__header--rtl .header__subtitle::after {
    left: -1.2rem;
  }

  .home__header.home__header--rtl::before {
    background-image: linear-gradient(to left, var(--header-background), var(--header-background) calc(100% - 75vw), transparent calc(100% - 25vw), transparent 100%);
  }
}

/* Frame Header Styles */
@media (min-width: 850px) {
  .home__header--frame .home__header-content {
    width: calc((min(100vw, var(--site-width)) * .50) - 2.4rem);
  }
  .home__header--frame.home__header::before {
    background: var(--header-background);
    background-image: none;
    width: calc(((100vw - min(100vw, var(--site-width))) / 2) + (min(100vw, var(--site-width))) * 0.50);
  }
  .home__header--frame .home__header-image {
    width: calc((((100vw - min(100vw, var(--site-width))) / 2) + (min(100vw, var(--site-width))) * 0.50) + 1.2rem);
  }
  .home__header--rtl.home__header--frame.home__header::before {
    left: unset;
    right: 50%;
    transform: translateX(50vw);
  }
}

/* Slant Header Styles */
@media (min-width: 850px) {
  .home__header--slant .home__header-content {
    width: calc((min(100vw, var(--site-width)) * 0.50) - 2.4rem);
  }
  .home__header--slant.home__header::before {
    background: var(--header-background);
    background-image: none;
    width: calc(((100vw - min(100vw, var(--site-width))) / 2) + (min(100vw, var(--site-width))) * 0.60);
    clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
  }
  .home__header--slant .home__header-image {
    width: calc((((100vw - min(100vw, var(--site-width))) / 2) + (min(100vw, var(--site-width))) * 0.60) + 1.2rem);
  }
  .home__header--rtl.home__header--slant.home__header::before {
    left: unset;
    right: 50%;
    transform: translateX(50vw);
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  .home__header--rtl.home__header--slant .home__header-content {
    width: calc((min(100vw, var(--site-width)) * 0.45) - 2.4rem);
  }
}

/* Box Header Styles */
@media (min-width: 850px) {
  .home__header--box .home__header-content {
    max-width: calc((min(100vw, var(--site-width)) * 0.60) - 2.4rem);
    width: fit-content;
    background-color: var(--header-background);
    padding: 5.8rem 5.8rem;
  }
  .home__header--box.home__header::before {
    display: none;
  }
  .home__header--box .home__header-image {
    width: 100vw;
  }
  .home__header--rtl.home__header--box .home__header-content {
    text-align: left;
  }
  .home__header--rtl.home__header--box .header__subtitle::after {
    left: 0;
    right: unset;
  }
}

/* Highlight Header Styles */
@media (min-width: 850px) {
  .home__header--hero .home__header-content, .home__header--highlight .home__header-content {
    width: calc((min(100vw, var(--site-width)) * .60) - 2.4rem);
    display: block;
    position: relative;
    direction: ltr;
  }
  .home__header--hero.home__header::before, .home__header--highlight.home__header::before {
    background: rgba(0, 0, 0, .15);
    background-image: none;
    width: 100vw;
  }
  .home__header--hero .header__title, .home__header--highlight .header__title {
    --line-height: var(--font-8);
    box-shadow: 
      inset 0px 0px 0px var(--line-height) var(--header-background), 
      0.8rem 0.4rem 0 0 var(--header-background), 
      -0.8rem -0.4rem 0 0 var(--header-background), 
      0.8rem -0.4rem 0 0 var(--header-background), 
      -0.8rem 0.4rem 0 0 var(--header-background);
    line-height: calc(1.2em + 1.2ex);
    display: inline;
    position: relative;
    top: 4.2rem;
  }
  .home__header--hero .home__header-image, .home__header--highlight .home__header-image {
    width: 100vw;
  }
  .home__header--hero .header__subtitle, .home__header--highlight .header__subtitle {
    text-shadow: 0 0 1.8rem rgba(0,0,0,.15), 0 0 3.6rem rgba(0,0,0,.33);
    font-weight: bold;
    position: absolute;
    top: 9.8rem;
    left: 0;
    transform: translateY(-100%);
    color: white !important;
  }
  .home__header--rtl.home__header--highlight .header__subtitle {
    left: unset;
    right: 0;
    margin-bottom: 1.8rem;
    font-size: var(--font-6);
  }
  .home__header--hero .header__subtitle::after, .home__header--highlight .header__subtitle::after {
    display: none;
  }
}

@media (max-width: 1180px) {
  .header__title {
    font-size: var(--font-7);
    --line-height: var(--font-7);
  }

  .header__subtitle {
    font-size: var(--font-4);
  }
}

@media (max-width: 880px) {
  .header__title {
    font-size: var(--font-6);
    --line-height: var(--font-6);
  }

  .header__subtitle {
    font-size: var(--font-3);
  }
}

/* Mobile Styles */
@media (max-width: 850px) {
  .home__header, .home__about {
    flex-flow: column;
  }
  .home__header-content, .home__about-text {
    width: 100%;
    margin: 0;
  }

  .home__header-image, .home__about-image {
    margin: 0 0 1.2rem 0;
    width: 100%;
    height: auto;
    position: relative;
  }

  .section.home__header {
    margin-bottom: 0;
    padding: 0.8rem 0 0;
    display: flex;
    flex-flow: column;
    box-shadow: 0 -12.8rem 0 0 var(--primary-10);
  }

  .home__header::before {
    background: none;
  }
  .home__header-content {
    background: linear-gradient(to top, var(--header-background), var(--header-background) calc(100% - 9.8rem), transparent calc(100%), transparent 100%);
    margin-top: -12.8rem;
    padding: 12.8rem 0.8rem 2.4rem;
    max-width: unset;
    width: 100%;
  }
  .home__ctas .email-form {
    margin-top: 1.8rem;
    flex-flow: column;
  }
  .home__header-image {
    width: 100vw;
    margin: -2.4rem -2.4rem 1.2rem;
    min-height: 25.6rem;
  }
  .home__ctas {
    display: flex;
    flex-flow: column;
    justify-content: center;
    bottom: 0;
    position: relative;
  }
  .home__ctas .home__ctas-list {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-flow: row;
    z-index: 999;
    gap: 0px;
    left: calc(100% - 4.8rem);
  }
  .home__ctas .home__ctas-button, .home__ctas-list-item {
    border-radius: 0px !important;
    height: 5.8rem;
    font-size: var(--font-3);
    line-height: 1.2;
    padding: 0 0.4rem;
  }
  .home__ctas:not(.home__ctas--footer) {
    margin-bottom: 0 !important;
    background-color: var(--primary) !important;
    border-radius: 0px !important;
    padding-top: 0px;
  }
  .home__ctas .donate, .home__ctas .home__ctas-or {
    width: 100%;
  }

  .header__title {
    font-size: 4.2rem;
    line-height: 1.2;
  }
  .header__subtitle {
    font-size: 2rem;
    margin-bottom: 2.4rem;
  }
  .header__subtitle::after {
    bottom: -1.2rem;
    height: 0.6rem;
  }
}